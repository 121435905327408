import styles from './stylesheets/chatlayout.module.css';
import profilephoto from './images/team/chat/android-chrome-192x192.png';
import profilephotouser from './images/team/chat/profile-user.png';
import { useState, useEffect,useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {io}  from 'socket.io-client'
import ChatSideBar from "./chatsidebar";
import {Button, ButtonGroup} from "@mui/material";


function ChatUx (){
    /*const [messages, setMessages] = useState([
        { id: 1, name: "Alice", profile: profilephoto, message: "Hi there!", type: "receiver" },
        { id: 2, name: "You", profile: profilephoto, message: "Hello!", type: "sender" },
    ]);*/



    const [messages, setMessages] = useState([]);

    const [input, setInput] = useState("");
    const [status, setStatus] = useState("Ongoing")

    const [whatsAppPhoneNumber, setWhatsAppPhoneNumber] = useState("");
    const [conversationId, setConversationId] = useState(0);

    const chatContainerRef = useRef(null);

    const location = useLocation();
    useEffect(() => {
        const socket = io("https://www.tabiri.ai",{
            path: "/chatserver/socket.io"
        });

        socket.on('received', (arg)=>{
            console.log('received'+arg);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    id: prevMessages.length + 1,
                    name: "Customer",
                    profile: profilephoto,
                    message: arg,
                    type: "receiver",
                    datetime:formatWhatsAppDateTime(new Date())
                },
            ]);
           // alert(arg);
        });
        // Cleanup when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryConversationId = query.get("conversationId");

        if (queryConversationId) {
            axios.get(`https://bot.tabiri.ai/api/whatsapp/conversation/${queryConversationId}`)
                .then(response => {
                    const transformedMessages = response.data.map((msg, index) => ({
                        id: index + 1,
                        name: msg.actor === "Bank" ? "Bank" : msg.actor ==="Bot"?"Bot":"Customer",
                        profile: msg.actor === "Bank" ? profilephoto :  msg.actor ==="Bot"?profilephoto:profilephotouser,
                        message: msg.message,
                        type: msg.actor === "Bot" ? "sender": msg.actor === "Bank"? "sender" : "receiver",
                        datetime:formatWhatsAppDateTime(new Date(msg.timeCreated))
                    }));
                    setMessages(transformedMessages);

                    const phoneNumber = response.data.find(msg => msg.actor !== "Bot" && msg.actor !== "Bank"  && msg.actor.length >= 12)?.actor;
                    if (phoneNumber) {
                        setWhatsAppPhoneNumber(phoneNumber);
                    }

                    const conversationStatus = response.data.find(msg => msg.conversationStatus)?.conversationStatus;
                    if (conversationStatus) {
                        if (conversationStatus === "HANDOVER")
                        {
                            setStatus("Ongoing");
                        }
                        else
                        {
                            setStatus(conversationStatus);
                        }
                    }

                    setConversationId(queryConversationId);
                })
                .catch(error => {
                    console.error("Error fetching messages:", error);
                });
        }
    }, [location.search]);

    useEffect(()=>{
        scrollToBottom();
    },[messages])

    const handleSend = () => {
        if (input.trim()) {
            setMessages([
                ...messages,
                { id: messages.length + 1, name: "Bank", profile:profilephoto, message: input, type: "sender",datetime:formatWhatsAppDateTime(new Date()) },
            ]);

            let requestData = {
                phoneNumber: whatsAppPhoneNumber,
                messageBody: input,
                conversationStatus: null,
                conversationId: conversationId
            };

            axios.post('https://bot.tabiri.ai/api/whatsapp/send', requestData).then(function (response) {
                console.log(response);
            }).catch(function (error) {
                console.log(error);
            });

            setInput("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSend();
        }
    };

    const handleClickResolve = (e)=>{

        setStatus("COMPLETED");

        let requestData = {
            phoneNumber: whatsAppPhoneNumber,
            messageBody: null,
            conversationStatus: "COMPLETED",
            conversationId: conversationId
        };

        axios.post('https://bot.tabiri.ai/api/whatsapp/send', requestData).then(function (response) {
            console.log(response);
            setStatus(response.data.body);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const handleClickEscalate = (e)=>{
        setStatus("Escalated");

        let requestData = {
            phoneNumber: whatsAppPhoneNumber,
            messageBody: null,
            conversationStatus: "ESCALATED",
            conversationId: conversationId
        };

        axios.post('https://bot.tabiri.ai/api/whatsapp/send', requestData).then(function (response) {
            console.log(response);
            setStatus(response.data.body);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const scrollToBottom = ()=>{

        if(chatContainerRef.current){

            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

        }

    }
    function formatWhatsAppDateTime(date) {
        const now = new Date();
        const givenDate = new Date(date);

        // Helper functions
        const formatTime = (date) => {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
            return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
        };

        const formatDate = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are zero-based
            const year = date.getFullYear();
            return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
        };

        // Calculate time difference
        const differenceInDays = Math.floor(
            (now - givenDate) / (1000 * 60 * 60 * 24)
        );

        // Format based on the time difference
        if (differenceInDays === 0 && now.getDate() === givenDate.getDate()) {
            // Same day (today)
            return formatTime(givenDate);
        } else if (differenceInDays === 1 || (now.getDate() - givenDate.getDate() === 1 && now.getMonth() === givenDate.getMonth() && now.getFullYear() === givenDate.getFullYear())) {
            // Yesterday
          //  return "Yesterday";
            return formatDate(givenDate) + ' '+ formatTime(givenDate);
        } else {
            // Older or future dates
            return formatDate(givenDate) + ' '+ formatTime(givenDate);
        }
    }

// Example Usage
    console.log(formatWhatsAppDateTime(new Date())); // e.g., "2:34 PM" (if today)
    console.log(formatWhatsAppDateTime(new Date(Date.now() - 86400000))); // "Yesterday"
    console.log(formatWhatsAppDateTime("2024-12-25T10:00:00Z")); // "25/12/2024"


    return (
        <>
            <ChatSideBar/>
        <div className={styles.chatcontainer}>
            <div className={styles.chatmessages} ref={chatContainerRef}>
                {messages.map((msg) => (
                    <div
                        key={msg.id}
                        className={`${ msg.name === "Customer"?styles.chatmessage:styles.chatmessageend} ${msg.type}`}>
                        <img src={msg.profile} alt={msg.name} className={styles.profilepic} />
                        <div className={styles.messagecontent}>
                            <span className={styles.messagename}>{msg.name}</span>
                            <p className={styles.messagetext}>{msg.message}</p>
                            <span className={styles.messagedatetime}>{msg.datetime}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.chatinput}>
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <button onClick={handleSend}>
                    <i className={styles.sendicon}>&#9658;</i>
                </button>
            </div>
        </div>
            <div className={styles.actionbuttonslayout}>
                <p className={styles.statuslabel}>Status: <span>{status}</span></p>
                <ButtonGroup variant="outlined"  aria-label="Loading button group">
                    <Button onClick={handleClickEscalate}>Escalate</Button>
                    <Button onClick={handleClickResolve}>Resolve</Button>

                </ButtonGroup>
            </div>
            </>
    )
}

export default ChatUx;