import Fxcomparisonheader from "../components/fxcomparisonheader";
import FxComparisonTable from "../components/FxComparisonTable";
import InitializeGoogleAds from "../components/InitializeGoogleAds";

function fxComparison(){
return(
    <>
        <Fxcomparisonheader></Fxcomparisonheader>
        <FxComparisonTable></FxComparisonTable>
        <InitializeGoogleAds></InitializeGoogleAds>



        </>
    )

}

export default  fxComparison