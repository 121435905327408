
import CurrencyFlag from 'react-currency-flags';
import CountryCurrencyDropdown from "./FxComparisonDropDown";
import Styles from './stylesheets/fxcomparison.module.css'
//import ncba from './images/banks/ncba.png'
//import stanbic from './images/banks/stanbic.png'
import equity from './images/banks/equity.png'
import absa from './images/banks/absa.png'
import coop from './images/banks/coop.jpeg'
import familybank from './images/banks/newlogos/family_bank.png'
import primebank from './images/banks/newlogos/primebank.png'
import boa from './images/banks/newlogos/bank_of_africa_logo.png'
import sateliteforex from './images/banks/newlogos/setalite_logo.png'
import iandm from './images/banks/newlogos/im_bank_logo.png'
import arrow from './images/banks/exchange.png'
import gulfbank from './images/banks/newlogos/gulf_bank_logo.png'
import skyforex from './images/banks/newlogos/sky_logo.jpg'
import bankofbaroda from './images/banks/newlogos/bank_of_baroda.png'
import ncba from './images/banks/newlogos/NCBA_Logo.jpg'
import stanbic from './images/banks/newlogos/stanbic_logo.png'
//import CurrencyFlag from 'react-currency-flags';


import {useEffect, useState} from "react";
import axios from "axios";
function FxComparisonTable() {
    //<CurrencyFlag currency="USD" size="md"/>
    //<CurrencyFlag currency="EUR" size="md"/>
    const[pricesData, setPricesData] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [currencieshave, setCurrenciesHave] = useState([])
    const [currencieswant, setCurrenciesWant] = useState([])
    const [tableOutPut, setTableOutput] = useState([])
    const[haveCurrency, setHaveCurrency] = useState("KES")
    const[wantCurrency, setWantCurrency] = useState("")
    const[amount, setAmount] = useState(0)
    const[midrate, setMidRate] = useState([{lastUpdated:"2 hours ago"}])

    const TYPE_IHAVE = "ihave"
    const TYPE_IWANT = "iwant"
    useEffect(()=>{
        axios.get('https://gbi5i3l03i.execute-api.eu-west-2.amazonaws.com/prod/forex-providers').then(function (response) {

            //alert(JSON.stringify(response))
            setPricesData(response.data)
            let filteedrCurrencies = response.data.filter((value)=>{

             return value.providerName !== "Central Bank of Kenya"
            })
            let parsedCurrencies = getAvailableCurrencies(filteedrCurrencies)
            setCurrencies(parsedCurrencies)
            setCurrenciesHave(parsedCurrencies)
            let mycurrencies = parsedCurrencies.filter((value)=>{

                return value !== "KES"

            });
            setCurrenciesWant(mycurrencies)
            setWantCurrency(mycurrencies[0])

        }).catch((error)=>{

            console.log(error)
        })

    },[])

    async function saveCurrencySearch(fromCurrency, toCurrency, amount) {

        try {
            let response = await fetch("https://api64.ipify.org?format=json");
            let data = await response.json();
            let ip = data.ip;
            axios.post('https://gbi5i3l03i.execute-api.eu-west-2.amazonaws.com/prod/forex-comparison-logs', {
                "amount": amount,
                "fromCurrency": fromCurrency,
                "toCurrency": toCurrency,
                "ipAddress": ip,
            }).then(function (response) {
                console.log(response.data.body)

            }).catch(function (error) {
                console.log(error)
            })
        } catch (error) {
            console.error("Error fetching IP:", error);

        }




    }

    function getAvailableCurrencies(data = []){
        let currencies = new Set()
        currencies.add('KES')
        data.forEach(function (value, index, array) {
            let gotCurrency = value.currency.replaceAll("/KES", "").replaceAll("KES/", "").trim()
            if(!currencies.has(gotCurrency)){
                currencies.add(gotCurrency)
            }

        })

        return [...currencies]


    }
    function filterCurrencies(currency){

        let filterered = currencies.filter((value, index, array)=>{

            return value === currency
        })

        return filterered
    }

    function showUnselectedCurrencies(currency){

        let filterered = currencies.filter((value, index, array)=>{

            return value !== currency
        })

        return filterered
    }

// Function to receive data from child
    const handleOnSelectChange = (value,type) => {
        console.log("Value from Child:", value);

        if(type === TYPE_IHAVE){
            setHaveCurrency(value)
            //buying foreign currency
            if(value === "KES"){
                setCurrenciesWant(showUnselectedCurrencies("KES"))
            }else{
             setCurrenciesWant(filterCurrencies("KES"))
                setWantCurrency("KES")
            }

        }else if(type === TYPE_IWANT){
            setWantCurrency(value)
            //selling foreign currency
            if(value === "KES"){

            }else{


            }
        }


       // alert(value + " "+ type)
    };

   const handleTextBoxChange = (e)=>{
       if(e.target.value === ""){
        setAmount(0)
       }else {
           if (/^\d*$/.test(e.target.value)) {
               setAmount(parseInt(e.target.value))
//alert("ok");

           }
       }
   }

   function setProviderImage(name) {

       if (name === "I&M") {

           return iandm

       } else if (name === "Family Bank") {

           return familybank

       } else if (name === "Satellite Forex") {

           return sateliteforex
       } else if (name === "Sky Forex") {
           return skyforex

       } else if (name === "BOA") {

           return boa
       } else if (name === "Gulf Bank") {

           return gulfbank
       }else if(name === "Bank of Baroda"){
           return bankofbaroda
       }else if(name === "Stanbic Bank"){
           return stanbic
       }else if(name === "NCBA"){
           return ncba
       }else if(name === "Prime Bank"){
           return  primebank
       }
   }

       function timeElapsed(inputDate) {
           const now = new Date();
           const past = new Date(inputDate);
           const diffInSeconds = Math.floor((now - past) / 1000);

           if (diffInSeconds < 60) {
               return `${diffInSeconds} seconds ago`;
           }

           const diffInMinutes = Math.floor(diffInSeconds / 60);
           if (diffInMinutes < 60) {
               return `${diffInMinutes} minutes ago`;
           }

           const diffInHours = Math.floor(diffInMinutes / 60);
           if (diffInHours < 24) {
               return `${diffInHours} hours ago`;
           }

           const diffInDays = Math.floor(diffInHours / 24);
           if (diffInDays < 30) {
               let day = "day"
               let days = "days"
               return `${diffInDays} ${diffInDays  === 1 ? day : days}  ago`;
           }

           const diffInMonths = Math.floor(diffInDays / 30);
           return `${diffInMonths} months ago`;
       }

   useEffect(()=>{
       //alert(amount + " "+haveCurrency + " "+wantCurrency)
       let currency;
       if(haveCurrency === "KES") {
           currency = `${wantCurrency}/${haveCurrency}`
           if (currency.includes("TZS") || currency.includes("UGX") || currency.includes("RWF")) {
               currency = `${haveCurrency}/${wantCurrency}`

           }
       }else{
             currency = `${haveCurrency}/${wantCurrency}`
           if (currency.includes("TZS") || currency.includes("UGX") || currency.includes("RWF")) {
               currency = `${wantCurrency}/${haveCurrency}`

           }
       }

       //alert(currency)
       let exchangeProviders = pricesData.filter((value)=>{

           return (value.currency === currency) && (value.providerName !== "Central Bank of Kenya")&& (value.buyRate !== 0 && value.sellRate !== 0)
       });

       let exchangeProvidersCalculated = []
       exchangeProviders.forEach((value, index, array)=>{

           let logo = setProviderImage(value.providerName);
           let rate = 0
           let calculatedAmount = 0
           let buyrate = value.buyRate
           let sellrate = value.sellRate
          if(haveCurrency === "KES"){

              if(wantCurrency === "UGX" || wantCurrency === "RWF" || wantCurrency === "TZS"){
                  rate = buyrate
                  calculatedAmount = parseFloat((amount*rate).toFixed(2))
              }else{
                 rate = sellrate
                calculatedAmount = parseFloat((amount/rate).toPrecision(4))
              }

          }else{
              if(haveCurrency === "UGX" || haveCurrency === "RWF" || haveCurrency === "TZS"){
          rate = sellrate
                  calculatedAmount = parseFloat((amount/rate).toPrecision(4))
              }else{

                  rate = buyrate
                  calculatedAmount = parseFloat((amount*rate).toFixed(2))

              }

          }




           let website = value.website
           let dateUpdated = value.dateUpdated


           exchangeProvidersCalculated.push({logo, rate, calculatedAmount, website, dateUpdated})




       });
       let exchangeProvidersCalculatedSorted = exchangeProvidersCalculated.sort((a,b)=>{

           return b.calculatedAmount - a.calculatedAmount
       });
       //alert(exchangeProviders.length);
       //let maximumAmount = exchangeProvidersCalculatedSorted[0].calculatedAmount
       let maximumAmount = exchangeProvidersCalculatedSorted[0]?.calculatedAmount || 0;
      // let maximumAmount = 129
       //alert(maximumAmount)
       let finalOutput = []
       exchangeProvidersCalculatedSorted.forEach((value, index, array)=>{

           if(index === 0){
               let elapsedTime = timeElapsed(value.dateUpdated)
               finalOutput.push({logo:value.logo, calculatedAmount:`${isFinite(value.calculatedAmount) ? value.calculatedAmount.toLocaleString("en-US") : 0} ${wantCurrency}`, rate:value.rate, website:value.website, dateUpdated:value.dateUpdated, loss:0,elapsedTime:elapsedTime})
           }else{

               let calculated_loss = `-${parseFloat((maximumAmount - value.calculatedAmount).toPrecision(4))}`
               let elapsedTime = timeElapsed(value.dateUpdated)
               finalOutput.push({logo:value.logo, calculatedAmount:`${value.calculatedAmount.toLocaleString("en-US")} ${wantCurrency}`, rate:value.rate.toFixed(2), website:value.website, dateUpdated:value.dateUpdated, loss:calculated_loss, elapsedTime:elapsedTime})

           }

       });
       setTableOutput(finalOutput)

       console.log(exchangeProvidersCalculatedSorted)
       saveCurrencySearch(haveCurrency, wantCurrency, amount)

   }, [amount,wantCurrency,haveCurrency])
    return(
        <div className={Styles.mainlayout}>
          <div className={Styles.currencydropdownlayout}>
            <div>
                <p  className={Styles.textboxlabel}>I have</p>
            <CountryCurrencyDropdown values = {currencieshave} dropdowntype = "ihave" onSelectChange = {handleOnSelectChange}></CountryCurrencyDropdown>
            </div>
              <div className={Styles.textboxamountlayout}>
                  <div>
                  <p className={Styles.textboxlabel} id="amountlabel">Amount</p>
                  <input type={"text"} placeholder={"amount"} value={amount} onChange={handleTextBoxChange} maxLength={9} className={Styles.amounttextbox}/>
                  </div>

              </div>
             <div className={Styles.arrowlayout}>
                 <img src={arrow} className={Styles.arrowlogo}/>
             </div>
            <div>
                <p  className={Styles.textboxlabel}>I want</p>
                <CountryCurrencyDropdown values = {currencieswant} dropdowntype = "iwant" onSelectChange = {handleOnSelectChange}></CountryCurrencyDropdown>
            </div>
              <div className={Styles.midratelayout}>

                  <h3>1USD = 129 KES</h3>
                  <p>Mid market rate</p>
                  <small>last updated {midrate[0].lastUpdated}</small>
              </div>

          </div>

            <div className={Styles.tablelayout}>
                <div className={Styles.tablerowsheader}>
                <div><p>Provider</p></div>
                <div><p>Rate</p></div>
                <div><p>You get</p></div>
                <div><p>Action</p></div>
                </div>

                    {tableOutPut.map(( item,index)=>(

                        <div key={index} className={Styles.tablerows}>
                        <div><img src={item.logo}/></div>
                        <div><p className={Styles.tablefontsize}>{item.rate}</p><small>{item.elapsedTime}</small></div>
                        <div><p className={Styles.tablefontsize} l>{item.calculatedAmount}</p><p className={Styles.savingsnegative}>{ item.loss === 0 ? '' : item.loss}</p></div>
                        <div>

                            <a className={Styles.tablefontsize} href={item.website} target={"_blank"}>Contact Us</a>
                        </div>
                        </div>

                        ))}




            </div>

        </div>




    )


}

export default FxComparisonTable