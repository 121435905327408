import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    SubTitle
} from 'chart.js';
import jsPDF from "jspdf";
import SidePanels from "./SidePanels";
import ChartButtons from "./ChartButtons";
import Styles from './stylesheets/linechart.module.css'
import { Line } from 'react-chartjs-2';
import {
    calculateEffectivePrice,
    calculateSalesROi,
    calculateSalesROiUpdated,
    calculateValuePackQuantity,
    filterProductPrice,
    formatDateReport,
    getMonthFormatted,
    getPredictedQty,
    getPredictedResults,
    getPredictedSales,
    getPrevious3MonthValues,
    getPreviousMonthValues, getSinglePackNameFromValuePack,
    getValuePackQuantity,
    isValuePack, setCarrefourArray, setNaivasArray, setToiiletCleanersArray, setToiletCleanersArray
} from "../utils/Utilities";
import {useRef} from "react";
import {downLoadChartImage} from "../utils/ReportUtilities";
import {
    BLEACHES_1_L,
    BLEACHES_2_L,
    BLEACHES_750ML,
    BLEACHES_BRANDS_STACKBAR, BLEACHES_BRANDS_STACKBAR_CARREFOUR, BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L,
    BLEACHES_BRANDS_STACKBAR_NAIVAS,
    BLEACHES_BRANDS_STACKBAR_NAIVAS_1L,
    BLEACHES_BRANDS_STACKBAR_NAIVAS_2L, CARREFOUR_RETAILER_ID, NAIVAS_RETAILER_ID,
    PREDICTED_LINE_CHART, TOILETCLEANERS_500ML,
    TOILETCLEANERS_BRANDS_STACKBAR, TOILETCLEANERS_BRANDS_STACKBAR_1L, TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR,
    TOILETCLEANERS_BRANDS_VALUES_STACKBAR
} from "../utils/Constants";
import StackedBarChart from "./StackedBarchart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    SubTitle
);

function LineChart(props){

    //alert(props.last3MonthSalesList.length);
  let line;
  const lineref = useRef(null)

    const title = props.showPlaceHolder?props.placeholdercharttitle:props.charttitle;
  const subtitle    =   props.linecharttype === PREDICTED_LINE_CHART?props.chartsubtitlePredict:props.chartsubtitleLocked;
     const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                fullSize:true,
                text: title,
                font: {
                    size: "26",
                    family: "Arial, Helvetica, sans-serif",
                    style: "italic",
                    weight: "bold"
                }
            },
            subtitle: {
                display: true,
                fullSize:true,
                text: subtitle,
                font: {
                    size: "22",
                    family: "Arial, Helvetica, sans-serif",
                    style: "italic",
                    weight: "bold"
                }
            }
        },

        scales: {
            x: {
                ticks: {
                    font: {
                        size: 14, // Adjust the font size as needed
                    }
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                offset: false,
                title: {
                    display: true,
                    text: 'Qty in Pieces',
                    font: {
                        size: 16,
                        weight: 'bold',
                        family: "Arial, Helvetica, sans-serif",
                        style: "italic",

                    }
                },
                ticks: {
                    font: {
                        size: 15, // Adjust the font size as needed
                    }
                }
            },
            y1: {
                type: 'linear',
                display: false,
                position: 'right',
                offset: false,
                grid: {
                    drawOnChartArea: false,
                },

            },
        },
    };

   // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];



    const labels = props.xAxis;
    const data = {
        labels,
        datasets: [
            {
                label: 'Actual Qty',
                data: props.yAxis,       // data: [234, 594, 585, 875, 43, 903, 1999],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            },
            {
                label: 'Predicted Qty',
                data: props.yAxisPredicted,                        //data: [234, 594, 585, 875, 43, 903, 2300],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
            },
        ],
    };

    const predictedValuesArray =  props.yAxisPredicted


    //const actualValuesArray = props.yAxis

    //calculate side panel values
//alert(props.effectivePrice);

    //alert(props.effectivePrice);
    //sales
    let sales= 0, salesAmount = 0,salesTotalAmount = 0,salesGrowth = 0, effectivePrice = 0, brandsSalesTotal = 0, currentPrice = 0,salesUplift=0,marketShare = 0, previousMarketShare=0, marketShareGain=0,result,  previous3MonthsTotal = 0, calculatedPanelValues = [], brandsEffectivePrice = 0, brandsCurrentPrice = 0, previousMonthsTotal = 0,  previousMonthsFiltered =[],previous3MonthsFiltered =[] ;
    if(props.showStackBar) {
        let totalQty = 0, subtotalAmount = 0;

        if(props.category.toLowerCase().includes("bleach")){
            if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID || parseInt(props.retailerId) === CARREFOUR_RETAILER_ID) {
                if (props.size === BLEACHES_750ML) {
                    if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID) {
                        brandsCurrentPrice = filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_CARREFOUR[0], props.retailerId, props.category)
                        brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_CARREFOUR[0], props.retailerId, props.category), props.discount, props.period);

                    }else {
                        brandsCurrentPrice = filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_NAIVAS[0], props.retailerId, props.category)
                        brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_NAIVAS[0], props.retailerId, props.category), props.discount, props.period);
                    }
                    //alert(brandsCurrentPrice + " "+brandsEffectivePrice);

                } else if (props.size === BLEACHES_1_L) {
                    //alert("ok");

                    if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                        brandsCurrentPrice = filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L[0], props.retailerId, props.category)
                        brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L[0], props.retailerId, props.category), props.discount, props.period);

                    }else {
                        brandsCurrentPrice = filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_NAIVAS_1L[0], props.retailerId, props.category)
                        brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_NAIVAS_1L[0], props.retailerId, props.category), props.discount, props.period);

                    }

                   // alert(brandsCurrentPrice + ""+  brandsEffectivePrice);
                } else if (props.size === BLEACHES_2_L) {
                    brandsCurrentPrice = filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_NAIVAS_2L[0], props.retailerId, props.category)
                    brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR_NAIVAS_2L[0], props.retailerId, props.category), props.discount, props.period);

                }
                //carrefour
            }else{


                brandsCurrentPrice = filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR[0], props.retailerId, props.category)
                brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, BLEACHES_BRANDS_STACKBAR[0], props.retailerId, props.category), props.discount, props.period);

            }

        }else if(props.category.toLowerCase().includes("toilet")){

            if(props.size === TOILETCLEANERS_500ML) {

                brandsCurrentPrice = filterProductPrice(props.priceList, TOILETCLEANERS_BRANDS_STACKBAR[0], props.retailerId, props.category)
                brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, TOILETCLEANERS_BRANDS_STACKBAR[0], props.retailerId, props.category), props.discount, props.period);
           // alert(brandsCurrentPrice +" "+brandsEffectivePrice);
            }else{
                brandsCurrentPrice = filterProductPrice(props.priceList, TOILETCLEANERS_BRANDS_STACKBAR_1L[0], props.retailerId, props.category)
                brandsEffectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, TOILETCLEANERS_BRANDS_STACKBAR_1L[0], props.retailerId, props.category), props.discount, props.period);
              // alert(brandsCurrentPrice + " "+brandsEffectivePrice);

            }

        }else{
            brandsEffectivePrice = props.linecharttype === PREDICTED_LINE_CHART?props.effectivePrice:props.lockedEffectivePrice
            brandsCurrentPrice = props.currentPrice;
            //diapers
        }

        Object.keys(props.predictedResults).forEach(key => {

            if(props.category.toLowerCase().includes("bleach")){
                let results;

                if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID || parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                    if (props.size === BLEACHES_750ML) {
                        if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID) {
                            results = getPredictedResults(BLEACHES_BRANDS_STACKBAR_CARREFOUR, key);
                        }else {
                            results = getPredictedResults(BLEACHES_BRANDS_STACKBAR_NAIVAS, key);
                        }
                    } else if (props.size === BLEACHES_1_L) {
                        if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                            results = getPredictedResults(BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L, key);
                        }else {
                            results = getPredictedResults(BLEACHES_BRANDS_STACKBAR_NAIVAS_1L, key);
                        }
                    } else if (props.size === BLEACHES_2_L) {
                        results = getPredictedResults(BLEACHES_BRANDS_STACKBAR_NAIVAS_2L, key);
                    }
                }else {
                    results = getPredictedResults(BLEACHES_BRANDS_STACKBAR, key);
                }
                if (results.foundStatus === true) {
                    totalQty += parseFloat(props.predictedResults[key].toFixed(0));
                   //alert(filterProductPrice(props.priceList,results.product,props.retailer, props.category));
                    //alert(props.retailer + props.category);
                    subtotalAmount+=  parseFloat(props.predictedResults[key].toFixed(0)) *  brandsEffectivePrice;
                  //  alert(props.priceList.length)
                    calculatedPanelValues.push({product:results.product, quantity:parseFloat(props.predictedResults[key].toFixed(0)), totalSales: parseFloat(props.predictedResults[key].toFixed(0)) *  calculateEffectivePrice(filterProductPrice(props.priceList,BLEACHES_BRANDS_STACKBAR[0],props.retailerId, props.category), props.discount, props.period)})


                }


                }else if(props.category.toLowerCase().includes("toilet")){

                let results2 ;
                if(props.size === TOILETCLEANERS_500ML){
                   // alert('ok');
                   // alert(key);
                    results2 = getPredictedResults(TOILETCLEANERS_BRANDS_STACKBAR, key)
                }else{
                    results2 = getPredictedResults(TOILETCLEANERS_BRANDS_STACKBAR_1L, key)

                }
                if (results2.foundStatus === true) {
                   // alert(key);
                    totalQty += parseFloat(props.predictedResults[key].toFixed(0));
                  // alert(totalQty);
                    subtotalAmount+=  parseFloat(props.predictedResults[key].toFixed(0)) *  brandsEffectivePrice;
                    //alert(subtotalAmount)
                    calculatedPanelValues.push({product:results2.product, quantity:parseFloat(props.predictedResults[key].toFixed(0)), totalSales: parseFloat(props.predictedResults[key].toFixed(0)) *  calculateEffectivePrice(filterProductPrice(props.priceList,TOILETCLEANERS_BRANDS_STACKBAR[0],props.retailerId, props.category), props.discount, props.period)})

                }

            }else if(props.category.toLowerCase().includes("diaper")){

                totalQty = predictedValuesArray[predictedValuesArray.length - 1];

            }


        });


        if(props.category.toLowerCase().includes("bleach")){

            if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID || parseInt(props.retailerId) === CARREFOUR_RETAILER_ID) {
                if (props.size === BLEACHES_750ML) {
                    if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID) {
                        previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[0] || value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[1]|| value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[2])
                        });
                    }else{
                        previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS[0] || value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS[1])
                        });
                    }
                } else if (props.size === BLEACHES_1_L) {
                    if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                        previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L[0])
                        });
                    }else {
                        previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS_1L[0])
                        });
                    }
                } else if (props.size === BLEACHES_2_L) {
                    previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                        return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS_2L[0]  )
                    });
                }
            }else{

                previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                    return (value.product === BLEACHES_BRANDS_STACKBAR[0] || value.product === BLEACHES_BRANDS_STACKBAR[1] || value.product === BLEACHES_BRANDS_STACKBAR[2])
                });
            }

            previousMonthsTotal = previousMonthsFiltered.reduce((total, value)=>{
                return total+value.quantity;

            },0);



        }else if(props.category.toLowerCase().includes("toilet")){
            if(props.size === TOILETCLEANERS_500ML) {
                previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                    return (value.product === TOILETCLEANERS_BRANDS_STACKBAR[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[7])
                });

                previousMonthsTotal = previousMonthsFiltered.reduce((total, value) => {
                    return total + value.quantity;

                }, 0)
            }else{
                if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID) {
                    previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                        return (value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[7])
                    });
                }else if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                    previousMonthsFiltered = props.previousMonthResults.filter((value) => {

                        return (value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[7])
                    });
                }

                previousMonthsTotal = previousMonthsFiltered.reduce((total, value) => {
                    return total + value.quantity;

                }, 0)
                //alert(previousMonthsTotal);
                
            }


            //diapers huggies
        }else{

            previousMonthsTotal = props.previousMonthSelectedProductQty;

        }



        //


        if(props.category.toLowerCase().includes("bleach")){


            if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID || parseInt(props.retailerId) === CARREFOUR_RETAILER_ID) {
                if (props.size === BLEACHES_750ML) {
                    if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                        previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[0] || value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[1]|| value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[2])
                        });
                    }else {
                        previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS[0] || value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS[1])
                        });
                    }
                } else if (props.size === BLEACHES_1_L) {
                    if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                        previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L[0])
                        });
                    }else {
                        previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {

                            return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS_1L[0])
                        });
                    }

                } else if (props.size === BLEACHES_2_L) {
                    previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {

                        return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS_2L[0] )
                    });
                }
            }else{

                previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {

                    return (value.product === BLEACHES_BRANDS_STACKBAR[0] || value.product === BLEACHES_BRANDS_STACKBAR[1] || value.product === BLEACHES_BRANDS_STACKBAR[2])
                });
            }



            let previous3MonthsTotalReduce = previous3MonthsFiltered.reduce((total, value)=>{
                return total+value.last_3_months_avg_sales_values;

            },0);

            previous3MonthsTotal = parseFloat(previous3MonthsTotalReduce.toFixed(0));



        }else if(props.category.toLowerCase().includes("toilet")){
            if(props.size === TOILETCLEANERS_500ML) {
                previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {
                    return (value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[0] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[1] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[2] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[3] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[4] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[5] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[6] || value.product === TOILETCLEANERS_BRANDS_VALUES_STACKBAR[7])

                });
            }else{
                if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID) {
                    previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {
                        return (value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[7])

                    });
                }else if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                    previous3MonthsFiltered = props.last3MonthSalesList.filter((value) => {
                        return (value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[7])

                    });
                }
            }

            let previous3MonthsTotalReduce = previous3MonthsFiltered.reduce((total, value)=>{
                return total+value.last_3_months_avg_sales_values;

            },0);

            previous3MonthsTotal = parseFloat(previous3MonthsTotalReduce.toFixed(0));
//alert(previous3MonthsTotal);

            //diapers huggies
        }else{

            previous3MonthsTotal = props.last3monthValue;

        }




         salesAmount = (subtotalAmount).toLocaleString('en-US')


        sales = (totalQty);

        salesTotalAmount = subtotalAmount;
        // alert( subtotalAmount)
       // alert(props.last3MonthSalesList.length);
        salesGrowth = ((salesTotalAmount - previous3MonthsTotal)).toFixed(0);
        salesUplift = (((salesTotalAmount - previous3MonthsTotal)/previous3MonthsTotal)*100).toFixed(2);
         //alert(previous3MonthsTotal);
        //market share
         marketShare = ((totalQty/props.totalPredictedSales)*100).toFixed(2);

         //get brands total

      // alert(previosMonthsTotal);
         previousMarketShare = ((previousMonthsTotal/props.previousMonthTotalQty)*100).toFixed(2);


         marketShareGain = (parseFloat(marketShare) - parseFloat(previousMarketShare)).toFixed(2);
       if(props.category.toLowerCase().includes('bleach')){
           result = calculateSalesROiUpdated(totalQty, brandsEffectivePrice,previousMonthsTotal,brandsCurrentPrice, previous3MonthsTotal);

       }else if(props.category.toLowerCase().includes("toilet")){
           result = calculateSalesROiUpdated(totalQty, brandsEffectivePrice
               ,previous3MonthsTotal, brandsCurrentPrice, previous3MonthsTotal);

       }else{
           //diapers
           result = calculateSalesROiUpdated(predictedValuesArray[predictedValuesArray.length - 1],(props.linecharttype === PREDICTED_LINE_CHART?props.effectivePrice:props.lockedEffectivePrice),predictedValuesArray[predictedValuesArray.length - 2], props.currentPrice, props.last3monthValue);

       }
              }else{

        if(isValuePack(props.product)){
            effectivePrice = parseFloat(((props.currentPrice - props.absolutediscount)/getValuePackQuantity(props.product)).toFixed(0));
             currentPrice = props.category.toLowerCase().includes("bleach")?props.currentPrice:props.currentPrice;


        }else{

            effectivePrice = props.linecharttype === PREDICTED_LINE_CHART ?props.effectivePrice : props.lockedEffectivePrice
            currentPrice = props.currentPrice;
        }
      //show linechart
        // salesAmount = (props.product,predictedValuesArray[predictedValuesArray.length - 1] * (props.linecharttype === PREDICTED_LINE_CHART ? props.effectivePrice : props.lockedEffectivePrice)).toLocaleString('en-US')

       // salesAmount = (props.product,predictedValuesArray[predictedValuesArray.length - 1] * effectivePrice).toLocaleString('en-US')
        salesAmount = (predictedValuesArray[predictedValuesArray.length - 1] * effectivePrice).toLocaleString('en-US')

         sales = (predictedValuesArray[predictedValuesArray.length - 1]);

       // salesTotalAmount = (props.product,predictedValuesArray[predictedValuesArray.length - 1] * (props.linecharttype === PREDICTED_LINE_CHART?props.effectivePrice:props.lockedEffectivePrice));
        salesTotalAmount = (predictedValuesArray[predictedValuesArray.length - 1] *effectivePrice);

        salesGrowth = ((salesTotalAmount - props.last3monthValue)).toFixed(0);
        salesUplift = (((salesTotalAmount - props.last3monthValue)/props.last3monthValue)*100).toFixed(2);

        //market share
         marketShare = ((predictedValuesArray[predictedValuesArray.length - 1]/props.totalPredictedSales)*100).toFixed(2);

         previousMarketShare = ((props.previousMonthSelectedProductQty/props.previousMonthTotalQty)*100).toFixed(2);


         marketShareGain = (parseFloat(marketShare) - parseFloat(previousMarketShare)).toFixed(2);
         //result = calculateSalesROiUpdated(predictedValuesArray[predictedValuesArray.length - 1],(props.linecharttype === PREDICTED_LINE_CHART?props.effectivePrice:props.lockedEffectivePrice),predictedValuesArray[predictedValuesArray.length - 2], props.currentPrice, props.last3monthValue);
        result = calculateSalesROiUpdated(predictedValuesArray[predictedValuesArray.length - 1],effectivePrice,predictedValuesArray[predictedValuesArray.length - 2],currentPrice, props.last3monthValue);

    }
    //sales uplift (predicted - prevqty)/prevqty
   // const salesUplift = (((predictedValuesArray[predictedValuesArray.length - 1] - predictedValuesArray[predictedValuesArray.length - 2])/predictedValuesArray[predictedValuesArray.length - 2])*100).toFixed(2);
  // alert(props.effectivePrice);

    
   //alert(salesAmount + " "+ props.last3monthValue);


    //return on investement
    //ROI = (Sales Growth - (disc*prev_qty)) / (disc*prev_qty);

    // sales growth  = (predicted quantity * price used to predict) - (previous quantity * previous price)

    //alert(props.previousPrice);
   // const salesGrowth =  (predictedValuesArray[predictedValuesArray.length - 1] * props.currentPrice) - (predictedValuesArray[predictedValuesArray.length - 2] * props.previousPrice);

   // const ROI = (((salesGrowth - (props.currentPrice - props.previousPrice) * predictedValuesArray[predictedValuesArray.length - 2])/((props.currentPrice - props.previousPrice) * predictedValuesArray[predictedValuesArray.length - 2]))*100).toFixed(2);

    //const ROI = calculateSalesROi(predictedValuesArray[predictedValuesArray.length - 1],props.effectivePrice,predictedValuesArray[predictedValuesArray.length - 2], props.previousPrice )

   // alert(result.ROI + " "+result.Investment);
    let csvfile_title = `${props.product.replace(" ","_").trim()}_${props.retailer}_${getMonthFormatted(props.date)}_${ formatDateReport(new Date())}`
   let csv_data = [];
    if(props.showStackBar){
   //  let stackbarbrands = props.category.toLowerCase().includes("bleach")?props.retailer === "Naivas"? BLEACHES_BRANDS_STACKBAR_NAIVAS:BLEACHES_BRANDS_STACKBAR:TOILETCLEANERS_BRANDS_STACKBAR;
        let stackbarbrands = props.category.toLowerCase().includes("bleach")?props.retailer === "Naivas"?setNaivasArray(props.size):setCarrefourArray(props.size):setToiletCleanersArray(props.size,props.retailerId);

        stackbarbrands.forEach((value, index, array)=>{
        //Retailer	Subcategory	Brand	Target	PredictionMonth	RRP	disc	Effective prce	PredictedQty	Predicted Sales	Prev3MonthSales	SalesGrowth	SalesUplift	Investment	ROI	MarketShare	MarketShareGain



//alert(props.category);

            let predictedQty = getPredictedQty(value, props.predictedResults);

            let currentPrice = 0, effectivePrice = 0, discount = 0;
            if(isValuePack(props.product)) {
            if(isValuePack(value)){

                    let discountValuePack;
                    currentPrice = props.category.toLowerCase().includes("bleach") ? filterProductPrice(props.priceList, getSinglePackNameFromValuePack(value), props.retailerId, props.category) : filterProductPrice(props.priceList, getSinglePackNameFromValuePack(value), props.retailerId, props.category);
                   discountValuePack = parseFloat(filterProductPrice(props.priceList, getSinglePackNameFromValuePack(value), props.retailerId, props.category) - (filterProductPrice(props.priceList, value, props.retailerId, props.category)/getValuePackQuantity(value)).toFixed(0));
                discount = props.absolutediscount + discountValuePack;
                   // effectivePrice = parseFloat(((filterProductPrice(props.priceList, value, props.retailerId, props.category) - props.absolutediscount) / getValuePackQuantity(value)).toFixed(0));
                 effectivePrice = currentPrice - discount;

                }else{
                    discount = 0;
                    currentPrice = filterProductPrice(props.priceList, value, props.retailerId, props.category);
                    effectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, value, props.retailerId, props.category), discount, props.period);

                }
                // currentPrice = 600;
                // effectivePrice = calculateEffectivePrice(600, props.discount, props.period);

            }else {

                if(isValuePack(value)){


                    currentPrice = props.category.toLowerCase().includes("bleach") ? filterProductPrice(props.priceList, getSinglePackNameFromValuePack(value), props.retailerId, props.category) : filterProductPrice(props.priceList, getSinglePackNameFromValuePack(value), props.retailerId, props.category);
                    effectivePrice = parseFloat(((filterProductPrice(props.priceList, value, props.retailerId, props.category)) / getValuePackQuantity(value)).toFixed(0));
                    discount = currentPrice - effectivePrice;
                }else {

                    discount = props.absolutediscount
                    currentPrice = filterProductPrice(props.priceList, value, props.retailerId, props.category);
                    //alert(value + " "+currentPrice);
                    effectivePrice = calculateEffectivePrice(filterProductPrice(props.priceList, value, props.retailerId, props.category), props.discount, props.period);
                }
                }


            brandsSalesTotal+= (predictedQty * effectivePrice);
           let listValues =  props.category.toLowerCase().includes("bleach")?(props.retailer === "Naivas"?setNaivasArray(props.size):setCarrefourArray(props.size)):setToiletCleanersArray(props.size,props.retailerId);
            let previous3monthvalue = getPrevious3MonthValues(listValues[index], props.last3MonthSalesList);
           // alert(previous3monthvalue);
             let previousQuantity = getPreviousMonthValues(value, props.previousMonthResults);
             let results = calculateSalesROiUpdated(predictedQty, effectivePrice,previousQuantity,currentPrice,previous3monthvalue)
             let got_marketShare = ((predictedQty/props.totalPredictedSales)*100).toFixed(2);
             let got_previousmarketshare =  ((previousQuantity/props.previousMonthTotalQty)*100).toFixed(2);
           //filterProductPrice(props.priceList,value,props.retailerId, props.category)

            csv_data.push({
                Retailer:props.retailer,
                Subcategory:props.category,
                brand:props.brand,
                Target: value,
                PredictionMonth: props.date,
                RRP:currentPrice,
                Disc:discount,
                PredictedQty:predictedQty ,
                EffectivePrice:effectivePrice,
                    Sales: (predictedQty * effectivePrice),
                Prev3MonthSales:previous3monthvalue ,
                SalesGrowth: `${(((predictedQty * effectivePrice)- previous3monthvalue)).toFixed(0)}`,
                SalesUplift:`${((((predictedQty * effectivePrice)- previous3monthvalue)/previous3monthvalue)*100).toFixed(2)}%`,
                    Investment: results.Investment,
                ROI: `${results.ROI}%`,
                MarketShare: `${ got_marketShare
                }%`,
                MarketShareGain: `${ parseFloat(got_marketShare ) - parseFloat(got_previousmarketshare)
                }%`
            });

        });

        salesTotalAmount = brandsSalesTotal;
        salesAmount = salesTotalAmount.toFixed(0).toLocaleString('en-US');
        salesGrowth = ((brandsSalesTotal - previous3MonthsTotal)).toFixed(0);
        salesUplift = (((brandsSalesTotal - previous3MonthsTotal)/previous3MonthsTotal)*100).toFixed(2);
         csv_data.push({
             Retailer:props.retailer,
             Subcategory:props.category,
             brand:props.category.toLowerCase().includes("bleach")?'Jik':'Harpic',
             Target: props.category.toLowerCase().includes("bleach")?'Jik Total':'Harpic Total',
             PredictionMonth: props.date,
             RRP: brandsCurrentPrice,
             Disc:0,
             PredictedQty: sales,
             EffectivePrice:0,
             Sales: salesAmount,
             Prev3MonthSales: previous3MonthsTotal,
             SalesGrowth: `${salesGrowth}`,
             SalesUplift: `${salesUplift}%`,
             Investment: result.Investment,
             ROI: `${result.ROI}%`,
             MarketShare: `${marketShare}%`,
             MarketShareGain: `${marketShareGain}%`
         });
        //salesGrowth = ((salesTotalAmount - previous3MonthsTotal)).toFixed(0);
        //salesUplift = (((salesTotalAmount - previous3MonthsTotal)/previous3MonthsTotal)*100).toFixed(2);


    }else {
        csv_data = [{
            Retailer:props.retailer,
            Subcategory:props.category,
            brand:props.brand,
            Target: props.product,
            PredictionMonth: props.date,
            RRP: currentPrice,
            Disc:props.absolutediscount,
            PredictedQty: sales,
            EffectivePrice:effectivePrice,
            Sales: salesTotalAmount,
            Prev3MonthSales: props.last3monthValue,
            SalesGrowth: `${salesGrowth}`,
            SalesUplift: `${salesUplift}%`,
            Investment: result.Investment,
            ROI: `${result.ROI}%`,
            MarketShare: `${marketShare}%`,
            MarketShareGain: `${marketShareGain}%`
        }]
    }

    //alert(props.lst3monthValue);
/**
    function generatePdfReport(imgData) {
        const doc = new jsPDF();
        const fontType = "Lato";
        const locale = "en-US";
        const fontColorHighLight = "#7030A0";
        const fontColorDefault = "#000000";
        doc.addFont(latoregular, "Lato", "regular");
        doc.addFont(latobold, "Lato", "bold");
         // console.log(doc.getFontList());
        doc.addImage(logo,'PNG', 10,5, logo.width, logo.height);
        doc.textWithLink("www.tabiri.ai", 10, 28, {url:'https://www.tabiri.ai'});
        doc.setFontSize(14);
        doc.setFont(fontType, "bold");
        doc.text("Date", 150, 18);
        doc.setFontSize(13);
        doc.setFont(fontType, "normal");
        let gen_date = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = gen_date.toLocaleDateString(locale, options)
        doc.text(formattedDate, 175, 18);

        if(props.imageurl !== null && props.imageurl !==undefined && props.imageurl.length !==0){

            let productImage = new Image();
            productImage.crossOrigin = 'anonymous';
            productImage.onload = function (){
                try {

                    doc.addImage(this, 'JPEG', 175, 22, 200, 200);
                }catch (e) {
                    console.log(e);
                   // alert(e);

                }

            };
            productImage.src = props.imageurl;

        }


        doc.setFontSize(18);
        doc.setFont(fontType, "bold");
        doc.text("Prediction Output - ", 10, 40);
        doc.setTextColor(fontColorHighLight);
        doc.text('"What if Analysis"',70,40);
        doc.setFontSize(15);
        //left side
        let titles_y_position = 60;
        let titles_y_position_offset = 3;
        let line_y_position = 61;
        doc.text("Target", 10, titles_y_position);
        doc.setDrawColor(fontColorHighLight)
        doc.line(10, line_y_position, 26, line_y_position);

        //right side
        doc.text("Levers Applied", 130, titles_y_position);
        doc.line(130, line_y_position, 165, line_y_position);

        // Add the details
        //left side
        doc.setFontSize(13);
        doc.setFont(fontType, "normal");
        doc.setTextColor(fontColorDefault)
        //targets
        let x_position_target_titles = 10;
        doc.text("Product: ", x_position_target_titles, titles_y_position +10 - titles_y_position_offset);
        doc.text("Category: ", x_position_target_titles, titles_y_position+20- titles_y_position_offset);
        doc.text("Retailer:" , x_position_target_titles, titles_y_position+30- titles_y_position_offset);
        doc.text("Month Predicted: ", x_position_target_titles, titles_y_position+40- titles_y_position_offset);

        doc.setFontSize(12);
        doc.setFont(fontType, "bold");
        //target values
        let x_position_target_values = 50
        doc.text(props.product, x_position_target_values, titles_y_position+10- titles_y_position_offset);
        doc.text(props.category, x_position_target_values, titles_y_position+20- titles_y_position_offset);
        doc.text(props.retailer, x_position_target_values, titles_y_position+30- titles_y_position_offset);

        let predicted_date = new Date(props.date); // Example date

        let options_predicted_date = { month: 'short', year: 'numeric' };
        let formattedPredictedDate = predicted_date.toLocaleDateString(locale, options_predicted_date);

        doc.text(formattedPredictedDate, x_position_target_values, titles_y_position+40- titles_y_position_offset);


        //levers
        doc.setFontSize(13);
        let x_position_levers_titles = 130;
        doc.setFont(fontType, "normal");
        doc.text("Price:", x_position_levers_titles, titles_y_position+10- titles_y_position_offset);
        doc.text("Discount: ", x_position_levers_titles, titles_y_position+20- titles_y_position_offset);
        doc.text("Period: ", x_position_levers_titles, titles_y_position+30- titles_y_position_offset);


        //levers values
        doc.setFontSize(12);
        let x_position_levers_values = 155;
        doc.setFont(fontType, "bold");
        doc.text(`${props.price}`, x_position_levers_values, titles_y_position+10- titles_y_position_offset);
        doc.text(props.discount + "%", x_position_levers_values, titles_y_position+20- titles_y_position_offset);
        doc.text(`${props.period} ${props.period > 1? 'weeks':'week'}`, x_position_levers_values, titles_y_position+30- titles_y_position_offset);

        doc.setTextColor(fontColorHighLight);
        doc.setFontSize(17);
        doc.text('Results', 10, 120);
        doc.line(10, 122, 31, 122);


        let img = new Image();
        img.onload = function() {
            // Calculate image dimensions
            var maxWidth = 220;
            var maxHeight = 140;

            const aspectRatio = img.width / img.height;

            // Calculate the new width and height based on the aspect ratio
            let newWidth = img.width;
            let newHeight = img.height;
            if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
            }
            if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
            }


            // Add the image to the PDF
            doc.addImage(this, 'PNG', 10, 125, newWidth, newHeight);

            let bottom_y_position = 260;
            doc.text("Conclusion", 10, bottom_y_position);
            doc.line(10, bottom_y_position +1, 42,bottom_y_position +1);
            doc.setFontSize(12);
            doc.setFont(fontType, "normal");
            doc.setTextColor(fontColorDefault);
            let  conclusion_text = `If you ran ${props.discount}% discount for ${props.product} at ${props.retailer} for ${props.period} ${props.period > 1 ? "weeks" : "week"} in ${formattedPredictedDate}; we expect a sales uplift/decline of ${salesUplift}%, total sales of ${sales} units (Ksh ${salesAmount}) and a Sales RoI of ${(ROI < 100) ? `-100%+` : `100%+`} `;
            justifyText(doc, conclusion_text, 10, bottom_y_position+6, 500, 7);
            doc.setFont(fontType, "bold");
            let footer_text = "Any questions? contact us at customercare@tabiri.ai";
            doc.text(footer_text, 10, bottom_y_position+25);
            let date = new Date().toLocaleString().trim().replace(" ", "").replace(",", "").toLowerCase();
            // Save the PDF
            doc.save(`tabiri_report_${date}.pdf`);
        };
        // Replace 'image.png' with the path to your image
        img.src = imgData;
        
    }
**/
if(props.showStackBar){
    //alert(props.retailerName);
    line = <StackedBarChart   retailerId = {props.retailerId} retailerName = {props.retailer} fmcgid = {props.fmcgId} segment = {props.segment} size = {props.size} date = {props.date}  product = {props.product} brand = {props.brand} previousValuesBrands = {props.previousValuesBrands} predictedResults = {props.predictedResults} category = {props.category}></StackedBarChart>

}else{

    line =  <Line options={options} data={data} />;
}
   // line =  <Line options={options} data={data} />;
    function shareReport() {
         let inputs = {imageurl:props.imageurl,product:props.product, category:props.category, retailer:props.retailer,retailers:[props.retailer], date:props.date, price:props.price, period:props.period, discount:props.discount, sales:sales, salesAmount:salesAmount, salesUplift:salesUplift, ROI:result.ROI, showStackBar:props.showStackBar}

         downLoadChartImage(inputs, "whatif", lineref.current);

    }

    //< 100) ? `-100%+` : `100%+`
     /**
    function downLoadChartImage(){


     if(lineref.current) {
         document.getElementById("chartbuttonslayout").style.visibility = "hidden";
         html2canvas(lineref.current)
             .then((canvas) => {
                 let imgData = canvas.toDataURL('image/png');
                 document.getElementById("chartbuttonslayout").style.visibility = "visible";

                 generatePdfReport(imgData);
             })
             .catch((error) => {
                 console.error('Error exporting chart:', error);
                 alert(error)
             });
     }




    }

    function justifyText(doc, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let currentY = y;

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const lineWidth = doc.getStringUnitWidth(line + word) * doc.internal.getFontSize();

            if (lineWidth < maxWidth) {
                line += (line ? ' ' : '') + word;
            } else {
                const spaceCount = line.split(' ').length - 1;
                const spaceWidth = (maxWidth - lineWidth) / spaceCount;

                doc.text(line, x, currentY);
                line = word;
                currentY += lineHeight;
            }
        }

        doc.text(line, x, currentY);
    }

    **/

    return(<div className={Styles.linechartlayout} ref={lineref}>
        <div className={Styles.linechartlayoutleft} >

            {line}
            <div id="chartbuttonslayout">
            <ChartButtons  onHandleLockPredictionClicked = {props.onHandleLockPrediction} onHandleShareButtonClicked = {shareReport} onHandleClearButtonClicked = {props.onHandleClearButtonClicked} disableLockPrediction = {props.disableLockPrediction} csvtitle = {csvfile_title} csvdata = {csv_data}/>
            </div>
            </div>
       <div className={Styles.linechartlayoutright}>
           <SidePanels title = "Sales (Value)" value = {sales} amount = {salesAmount}></SidePanels>
           <SidePanels title = "Sales Uplift (Value)" value = {`${salesUplift}%`}></SidePanels>
           <SidePanels  title = "ROI" value = {`${result.ROI}%`}></SidePanels>
           <SidePanels title = "Market Share (Qty)" value = {`${marketShare}%`}></SidePanels>
           <SidePanels title = "Market Share Gain" value = {`${marketShareGain}%`}></SidePanels>

       </div>
   </div>)

}

export default LineChart;