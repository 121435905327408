import { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import CurrencyFlag from "react-currency-flags";
//import Flags from 'react-world-flags';
//import './CountryCurrencyDropdown.css';
import styles from './stylesheets/fxcomparison.module.css'


export default function CountryCurrencyDropdown(prop) {

    const handleChange = (e)=>{

        let value = e.target.value
       // alert(value)
        prop.onSelectChange(value, prop.dropdowntype)


    }


    return (<>
            <div className={styles.currencydropdowncontainer}>

         <select onChange={handleChange}>
             {prop.values.map(( item,index)=>(
                 <option key={index} value={item}>
                     {item}
                 </option>

             ))}

         </select>
            </div>



        </>

    );
}
