import styles from './stylesheets/landingpage.module.css'
import logo from './images/tabirilogo.png'
import colgate from './images/brands/colgate2.png'
import kimberlyclark from './images/brands/kimberlyclark.png'
import reckitt from './images/brands/reckitt.png'
import simon from './images/team/simon.png'
import isaac from './images/team/isaac.png'
import gabriel from './images/team/gabriel.png'
import belinda from './images/team/belinda.png'
import jesse from './images/team/jesse.png'
import defaultphoto from './images/team/default_team.png'
import linkedin from './images/team/social_media_icons/linkedin.png'

import benefitsstep1 from './images/aitools/benefits/1.png'
import benefitsstep2 from './images/aitools/benefits/2.png'
import benefitsstep3 from './images/aitools/benefits/3.png'
import landingpage from './images/aitools/banner/landingpageholder.jpg'
import ingest from './images/aitools/howitworks/ingest-data@2x.png'
import customizemodel from './images/aitools/howitworks/customize-model@2x.png'
import predict from './images/aitools/howitworks/predict@2x.png'
import competitormoves from './images/aitools/benefits/competitor-moves@2x.png';
import optimize from './images/aitools/benefits/optimize@2x.png';
import highroi from './images/aitools/benefits/high-roi@2x.png';
import goal from "./images/aitools/benefits/fx/goal.png"
import twentyfourhours from "./images/aitools/benefits/fx/24hours.png"
import costs from "./images/aitools/benefits/fx/costs.png"

import {Close, Menu} from "@mui/icons-material";
import {useState} from "react";
import ContactUs from "./ContactUs";
import LandingPageHeader from "./LandingPageHeader";
import LandingpageFooter from "./LandingpageFooter";
import promovideo from "./videos/FXbotdemo1020poriginal.mp4";



function LandingPage(props){
    const [width, setWidth] = useState("-240px");
    const [showContactUs, setShowContactUs] = useState(false);
    const clickLoginButton =  (event)=>{

        props.onHandleLoginButton();

    }

    const clickCloseNavBar = (event)=>{

        setWidth("-240px");
        setShowContactUs(false)
    }
    const clickOpenNavBar = (event)=>{

        setWidth("0");
    }

    const clickContactUsNavBar = (event)=>{
        setWidth("-240px");
        setShowContactUs(true)

    }
    const clickContactUs = (event)=>{

        setShowContactUs(true)

    }

    const clickHyperLink = (event)=>{

        setShowContactUs(false)

    }

    const clickDemoButton = (event)=>{


        clickContactUsNavBar();


    }

    function scrollToSection() {
        let hash = window.location.hash;
        if (hash) {
            let target = document.querySelector(hash);
            if (target) {
                target.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    // Scroll to section when the page loads
    window.onload = scrollToSection;


// <!-- <h3>TABIRI <span>AI</span></h3>-->
    //  <img className={styles.bannerimage} src={landingpage}/>
    let layoutView;
    if(showContactUs){
        layoutView = <ContactUs/>;
    }else{
        layoutView = <>
            <div className={styles.mainsectionfx} id={"home"}>
                <div className={styles.mainsectionleft}>

                    <div className={styles.taglineslayout}>
                        <h1>Solving <span>Sales Conversion</span> in Financial Services using <span>AI</span></h1>
                        <p>Convert more FX customers! </p>
                        <button className="default_button_landingpage" onClick={clickDemoButton}>Request Demo</button>

                    </div>


                </div>
                <div className={styles.mainsectionright}>

                </div>

            </div>

            <div className={styles.contentsection} id={"howitworks"}>
                <h2 className={styles.sectiontitle}>How does it work?</h2>
                <div className={styles.contentsubsectionvideo}>
                    <div>
                        <p className={styles.subsectiontext}>
                            Tabiri FX is an AI bot that helps Banks to trade Forex with their customers and answer Forex-related queries via email or WhatsApp.
                        </p>

                        <p className={styles.subsectiontext}>
                            It can negotiate with the customer using natural language, arrive at an exchange rate that works for both parties, and complete the transaction with zero human intervention.
                        </p>

                        <p className={styles.subsectiontext}>
                            It also uses Machine Learning to learn and predict the expected FX demand from customers, engages the customers at the right time and effectively grows revenue per customer independently.
                        </p>







                    </div>
                    <div>
                        <video className={styles.videofx}  src={promovideo} controls={true} loop={true}autoPlay={true} muted={true}/>



                            </div>


                </div>
            </div>

            <div className={styles.contentsection} id={"benefits"}>
                <h2 className={styles.sectiontitle}>Benefits</h2>
                <div className={styles.contentsubsection}>
                    <div>
                        <h3 className={styles.subsectiontitle}>Close FX deals at a lower cost</h3>
                        <img className={styles.subsectionimage} src={costs}/>

                        <p className={styles.subsectiontext}>Automate smaller tickets using AI and close deals at a lower cost by reducing FX dealers time spent on small FX tickets  </p>
                               </div>
                    <div>
                        <h3 className={styles.subsectiontitle}>Provide faster customer service</h3>
                        <img className={styles.subsectionimage} src={twentyfourhours}/>

                        <p className={styles.subsectiontext}>Provide consistent 24/7 customer service to your customers no matter the number of request and time of day.</p>
                             </div>
                    <div>
                        <h3 className={styles.subsectiontitle}>Grow Revenue via Machine Learning</h3>
                        <img className={styles.subsectionimage} src={goal}/>

                        <p className={styles.subsectiontext}>Use our ML that learns (from dropped sales, negotiations and closed deals data) to predict demand and engage customers, cross sell and grow revenue automatically  </p>
                             </div>

                </div>
            </div>










            <div className={styles.contentsection} id={"aboutus"}>
                <h2 className={styles.sectiontitle}>Our Team</h2>
                <div className={styles.contentsubsection}>
                    <div className={styles.teamlayout}>
                        <img className={styles.teamphoto} src={simon}/>
                        <a href={"https://www.linkedin.com/in/ngigisimon/"}> <img className={styles.teamlinkedinlogo} src={linkedin}/></a>
                        <p className={styles.teamname}>Simon </p>
                        <p className={styles.teamrole}>CEO & Data Scientist</p>
                        <p className={styles.teamexperience}>Ex-Bain, INSEAD MBA, BSC Comp Science
                        </p>


                    </div>

                    <div className={styles.teamslayout}>
                        <img className={styles.teamphoto} src={isaac}/>
                        <a href={"https://www.linkedin.com/in/isaac-sichangi-525b4121b/"}><img className={styles.teamlinkedinlogo}src={linkedin}/></a>
                        <p className={styles.teamname}>Isaac</p>
                        <p className={styles.teamrole}>CTO & Data Scientist</p>
                        <p className={styles.teamexperience}>2X Founder, BSC Comp Science,DayStar University
                        </p>

                    </div>

                    <div className={styles.teamslayout}>
                        <img className={styles.teamphoto} src={gabriel}/>
                        <a href={"https://www.linkedin.com/in/gabriel-odero-114b11177/"}> <img className={styles.teamlinkedinlogo} src={linkedin}/></a>
                        <p className={styles.teamname}>Gabriel</p>
                        <p className={styles.teamrole}>Backend Engineer & Data Scientist</p>
                        <p className={styles.teamexperience}>MSC Comp Science, Coventry University,UK
                        </p>


                    </div>



                </div>
                <div className={styles.contentsubsection}>
                    <div className={styles.teamslayout}>
                        <img className={styles.teamphoto} src={belinda}/>
                        <a href={"https://www.linkedin.com/in/belinda-chepkemoi-068b61164"}> <img className={styles.teamlinkedinlogo} src={linkedin}/></a>
                        <p className={styles.teamname}>Belinda</p>
                        <p className={styles.teamrole}>Finance & Ops Lead</p>
                        <p className={styles.teamexperience}>BCom Accounting, University of Nairobi</p>


                    </div>

                </div>
            </div>



        </>
    }
    /**
     * <div className={styles.menuicon} onClick={clickOpenNavBar}> <Menu ></Menu>
     *             <img src={logo}/></div>
     *         <nav className={styles.mainnav}>
     *             <div className={styles.applogolayout}>
     *                 <img src={logo}/>
     *
     *             </div>
     *
     *             <ul className={styles.headernavlist}>
     *                 <li><a href="#home" onClick={clickHyperLink}>Home</a> </li>
     *                 <li><a href="#howitworks" onClick={clickHyperLink}>How it works</a> </li>
     *                 <li><a href="#benefits" onClick={clickHyperLink}>Benefits</a> </li>
     *                 <li><a href="#aboutus" onClick={clickHyperLink}>About Us</a> </li>
     *                 <li><a onClick={clickContactUs}>Contact Us </a> </li>
     *             </ul>
     *
     *             <button className="default_button_landingpage" onClick={clickLoginButton}>Login</button>
     *
     *         </nav>
     *
     *         <nav className={styles.mobilenav} style={{left:width}}>
     *
     *
     *             <ul className={styles.mobileheadernavlist}>
     *                 <li><a href="#home" onClick={clickCloseNavBar}>Home</a> </li>
     *                 <li><a href="#howitworks" onClick={clickCloseNavBar}>How it works</a> </li>
     *                 <li><a href="#benefits" onClick={clickCloseNavBar}>Benefits</a> </li>
     *                 <li><a href="#aboutus" onClick={clickCloseNavBar}>About Us</a> </li>
     *                 <li><a  onClick={clickContactUsNavBar}>Contact Us</a> </li>
     *             </ul>
     *
     *             <button className={styles.navbutton}  onClick={clickLoginButton}>Login</button>
     *
     *             <Close className={styles.closeicon} onClick={clickCloseNavBar}></Close>
     *         </nav>
     */
    return <>

        <LandingPageHeader type = "home" user = "fx" onClickContactUsNavBar = {clickContactUsNavBar} onClickCloseNavBar = {clickCloseNavBar} navwidth = {width} onClickLoginButton = {clickLoginButton} onClickContactUs = {clickContactUs} onClickOpenNavBar = {clickOpenNavBar} onClickHyPerLink = {clickHyperLink}/>
        {layoutView}


        <LandingpageFooter/>





    </>;



}

export default LandingPage;