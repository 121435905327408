function initializeGoogleAds() {
    if (!window.gtag) {
        // Add the Google Ads script dynamically
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16929607019"; // Replace with your Google Ads ID
        document.head.appendChild(script);

        // Initialize gtag function
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        window.gtag = gtag;
        gtag("js", new Date());
        gtag("config", "AW-16929607019"); // Replace with your Google Ads Conversion ID
    }
}

export  default  initializeGoogleAds