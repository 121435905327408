//import logo from './../logo.svg';
import './../App.css';
import Header from "./../components/header";
import Sidebar from "./../components/Sidebar";
//import SectionTitles from "./components/SectionTitles";
import Target from "./../components/Target";
import Levers from "./../components/Levers";
import Scenarios from "./../components/Scenarios";
//import ChartsLayout from "./components/ChartsLayout";
import RegistrationForm from "./../components/RegistrationForm";
import LoginForm from "./../components/LoginForm";
import ComingSoonImage from "./../components/ComingSoonImage";
import {useState, useEffect, useRef} from "react";
import LineChart from "./../components/LineChart";
import {CircularProgress} from "@mui/material";

import {
    calculateEffectivePrice,
    calculateSalesROiOptimize, calculateValueUplift, checkProductNameSizeMatch,
    formatDate,
    getMonthFormatted,
    getNextMonth,
    getPreviousMonth,
    getYearFormatted, isValuePack
} from "./../utils/Utilities"
import {
    MAX_OPTIMIZE_DISCOUNT,
    CARREFOUR_TOILETCLEANERS_MODEL_ID,
    NAIVAS_TOILETCLEANERS_MODEL_ID,
    NAIVAS_RETAILER_ID,
    CARREFOUR_BLEACHES_MODEL_ID,
    CARREFOUR_RETAILER_ID,
    NAIVAS_BLEACHES_MODEL_ID,
    RECKITT_FMCG_ID,
    BLEACHES_CURRENT_PRICES_STORAGE_KEY,
    TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY,
    PRICELIST_MILLISECONDS_KEY,
    REFRESH_PRICELIST_DURATION,
    CHECK_LOCKED_PREDICTION_REQUESTTYPE,
    WHATIF_REQUESTTYPE,
    MAIN_URL,
    DEFAULT_PREVIOUS_MONTH_DATE,
    LOAD_LOCKED_PREDICTION_REQUESTTYPE,
    PREDICTED_LINE_CHART,
    LOCKED_LINE_CHART,
    DEFAULT_CURRENT_MONTH_DATE,
    DIAPERS_MODEL_ID,
    DIAPERS_CURRENT_PRICES_STORAGE_KEY,
    KIMBERLY_CLARKS_FMCG_ID,
    ML_TARGETS_STORAGE_KEY, MLTARGETS_MILLISECONDS_KEY, BLEACHES_750ML, TOILETCLEANERS_500ML, TOILETCLEANERS_1L
} from "../utils/Constants";
import {ButtonGroup, Button} from "@mui/material";
import axios from "axios";
import DoghnutChartWhatIfAnalaysisLayout from "./../components/DoghnutChartWhatIfAnalaysisLayout";
import AutomateLevers from "./../components/AutomateLevers";
import AutomateResults from "./../components/AutomateResults";
import LandingPage from "./../components/LandingPage";
import AlertDialog from "../components/AlertDialog";
import {isArray} from "chart.js/helpers";
import {props} from "react-csv/lib/metaProps";
import StackedBarChart from "../components/StackedBarchart";
//import constructionImage from './../components/images/aitools/cunstruction/underconstruction.png';



function App() {


    const [showScenario, setScenario] = useState(false);
    const [showAutomate, setShowAutomate] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const[showMyPlan, setShowMyPlan] = useState(false);
    const [showDoghnutChartWhatIf, setShowDoghnutChartWhatIf] = useState(false);
    const [showStackBarWhatIf, setShowStackBarWhatIf] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showAuthentication, setShowAuthentication] = useState(true);
    const [showLoginScreen, setShowLoginScreen] = useState(true);
    const [showRegistrationScreen, setShowRegistrationScreen] = useState(false);
    const [showLoginLoader, setShowLoginLoader] = useState(false);
    const [showLockedPredictionButton,setShowLockedPredictionButton] =  useState(false);
    const [lockedPredictionValues,setLockedPredictionValues]=useState([]);
    const [showAutomateLoader, setShowAutomateLoader] = useState(false);
    const [showLandingPage, setShowLandingPage] = useState(true);
    const [showRegistrationLoader, setShowRegistrationLoader] = useState(false);
    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loginAlertMessage, setLoginAlertMessage] = useState("");
    const [showRegistrationAlert, setShowRegistrationAlert] = useState(false);
    const [showAutomateResults, setShowAutomateResults] = useState(false);
    const [registrationAlertType, setShowRegistrationAlertType] = useState("");
    const [registrationAlertMessage, setRegistrationAlertMessage] = useState("");
    const [userId, setUserId] = useState(localStorage.getItem("userid"));
    const [token, setToken] = useState(localStorage.getItem("token"));
      const [activeLink, setActiveLink] = useState(null);
    const[alertDialogStatus, setAlertDialogStatus] = useState(false);
    const[dialogTitle, setDialogTitle] = useState("");
    const [dialogText, setDialogText] = useState("");

    // const[selectedSubCategory, setSelectedSubCategory] = useState("Liquid Toilet Cleaner")
const[linechartType,setLineChartType]=useState(PREDICTED_LINE_CHART);

    //target levers
    const [fmcgid, setFmcgId] = useState(localStorage.getItem("fmcgid") === null?RECKITT_FMCG_ID:localStorage.getItem("fmcgid") === "0"? RECKITT_FMCG_ID:localStorage.getItem("fmcgid") );

    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [selecteModelId, setSelectedModelId] = useState("");
    const [product, setProduct] = useState("");
    const [productIndex, setProductIndex] = useState(0);
    const [category, setCategory] = useState("");
    const [subcategory, setSubCategory] = useState("");
    const [brand, setBrand] = useState("");
    const [size, setSize] = useState("");
    const [segment, setSegment] = useState("");
    const [filteredDiapersProducts, setFilteredDiaperProducts] = useState([]);

    useEffect(() => {
        if (parseInt(fmcgid) === KIMBERLY_CLARKS_FMCG_ID) {
            setSelectedSubCategory("Regular Open Diapers");
            setSelectedModelId(DIAPERS_MODEL_ID);
            setProduct("Huggies SC Size 4");
            setProductIndex(0);
            setCategory("Diapers");
            setSubCategory("Regular Open Diapers");
            setBrand("Huggies");
            setSize("Size 4");
            setSegment("SC");
        } else if (parseInt(fmcgid) === RECKITT_FMCG_ID) {
            setSelectedSubCategory("Regular Bleach");
            setSelectedModelId(NAIVAS_BLEACHES_MODEL_ID);
            setProduct("Jik Bleach Regular 750ml");
            setProductIndex(0);
            setCategory("Bleach");
            setSubCategory("Regular Bleach");
            setBrand("Jik");
            setSize("750ml");
        }
    }, [fmcgid]);
/**
    if(parseInt(fmcgid0 === KIMBERLY_CLARKS_FMCG_ID){
        const [selectedSubCategory, setSelectedSubCategory] = useState("Regular Open Diapers");
        const [selecteModelId, setSelectedModelId] = useState(DIAPERS_MODEL_ID);
        const [product, setProduct] = useState("Huggies SC Size 4");
        const [productIndex, setProductIndex] = useState(0);
        const [category, setCategory] = useState("Diapers");
        const [subcategory, setSubCategory] = useState("Regular Open Diapers");
        const [brand, setBrand] = useState("Huggies");
        const [size, setSize] = useState("Size 4");
        const [segment, setSegment] = useState("SC");
    }else if(parseInt(fmcgid)=== RECKITT_FMCG_ID){

        const[selectedSubCategory, setSelectedSubCategory] =  useState("Regular Bleach");
        const [selecteModelId, setSelectedModelId] = useState(NAIVAS_BLEACHES_MODEL_ID);
        const [product, setProduct] = useState("Jik Bleach Regular 750ml");
        const [productIndex, setProductIndex] = useState(0);
        const [category, setCategory] = useState("Bleach");
        const [subcategory, setSubCategory] = useState("Regular Bleach");
        const [brand, setBrand] = useState("Jik");
        const [size, setSize] = useState("750ml");
    }
 **/

    /**
    const[selectedSubCategory, setSelectedSubCategory] =  useState("Regular Bleach");
    const [selecteModelId, setSelectedModelId] = useState(NAIVAS_BLEACHES_MODEL_ID);
    const [product, setProduct] = useState("Jik Bleach Regular 750ml");
    const [productIndex, setProductIndex] = useState(0);
    const [category, setCategory] = useState("Bleach");
    const [subcategory, setSubCategory] = useState("Regular Bleach");
    const [brand, setBrand] = useState("Jik");
    const [size, setSize] = useState("750ml");
**/


    /**
     const [product, setProduct] = useState("Harpic Power Plus Citrus 500ml");
     const [category, setCategory] = useState("Toilet Cleaner");
     const [subcategory, setSubCategory] = useState("Liquid Toilet Cleaner");
     const [brand, setBrand] = useState("Harpic");
     const [size, setSize] = useState("500ml");
     **/




    const [constraint, setConstraint] = useState("Max Discount");
    const [objective, setObjective] = useState("Maximize Volume");
    const [maxconstraint, setMaxConstraint] = useState(0);
  //  const [budget, setBudget] = useState(0);
    const maxDiscount = useRef([
        {
            discount: 0,
            output: []
        }
    ]);

    const maxDiscount_1_retailer = useRef("");
    const maxDiscount_1 = useRef([
        {
            discount: 0,
            output: []
        }
    ]);
    const maxDiscount_2_retailer = useRef("");
    const maxDiscount_2 = useRef([
        {
            discount: 0,
            output: []
        }
    ]);

    const roiCalculationsResult = useRef([]);
    const roiCalculationsResult2 = useRef([]);
    const averagePriceAndQuantity = useRef([
        {
            fullDate: '',
            barcode: '',
            product: '',
            salesQuantity: 0,
            averageSalesQuantity: 0,
            averagePrice: 0
        }
    ]);






    const [retailer, setRetailer] = useState(3);
    const [retailerName, setRetailerName] = useState("Naivas");
    const selectedRetailers = useRef([]);
    const [date, setDate] = useState("0");
    const [disableLockPrediction,setDisableLockPrediction] = useState(true);
    const [previousDate, setPreviousDate] = useState("0");
    const [proposedPrice, setProposedPrice] = useState(0);
    const [effectivePrice, setEffectivePrice] = useState(0);
    const [placeHolderProductLabel, setPlaceHolderProductLabel] = useState();
    const [showPlaceHolderProductLabel, setShowPlaceHolderProductLabel] = useState(false);
    const[lockedEffectivePrice,setLockedEffectivePrice]=useState(0)
    const[lockedDiscount,setLockedDiscount]=useState(0);
    const[lockedPeriod,setLockedPeriod]=useState(1);

    //what if levers
    const [price, setPrice] = useState();
    const [discount, setDiscount] = useState(0);
    const [absoluteDiscount, setAbsoluteDiscount] = useState(0);
    const [period, setPeriod] = useState(1);
    const [barcode,setBarcode] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    //const [instoremarjeting, setInstoreMarketing] = useState();
    //const [retailerDiscount, setRetailerDiscount] = useState();
    const [currentPricesList, setCurrentPricesList] = useState([]);
    const [last3MonthAverageSalesList, setLast3MonthsAverageSalesList] = useState([]);
    const [last3MonthAverageValue, setLast3MonthAverageValue] = useState(0);

    const [lineChartYAxis, setLineChartYAxis] = useState([]);
    const [lineChartYAxisPredicted, setLineChartYAxisPredicted] = useState([]);
    const [lineChartXAxis, setLineChartXAxis] = useState([]);
    const [currentPriceOriginal, setCurrentPriceOriginal] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [previousPrice, setPreviousPrice] = useState(0);
    const [totalPredictedSalesQty, setTotalPredictedSalesQty] = useState(0);
    const [totalpreviousMonthQty, setTotalPreviousMonthQty] = useState(0);
    const [previousMonthProductQty, setPreviousMonthProductQty] = useState(0);
    const [marketViewArrayPredictedValues, setMarketViewArrayPredictedValues] = useState([]);
    const [marketViewArrayPredictedLabels, setMarketViewArrayPredictedLabels] = useState([]);
    const [marketViewArrayActualValues, setMarketViewArrayActualValues] = useState([]);
    const [marketViewArrayActualLabels, setMarketViewArrayActualLabels] = useState([]);
    const [predictedResults, setPredictedResults] = useState([]);
    const[previousValuesBrands, setPreviousValuesBrands] = useState([])
    const[peviousMonthResults, setPreviousMonthResults] = useState([]);

    const handleCloseAlertDialog = (event)=>{

        setAlertDialogStatus(false);
    }

    const loginUser = (formData) => {
        const requestData = {email: formData.email, password: formData.password}
        setShowLoginLoader(true);
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mluserlogin', requestData).then(function (response) {
            console.log(response);

            setShowLoginLoader(false);

            const data = response.data;

            if (data.status === "Success") {
               // alert(data.user_id);
                if(data.fmcgId.toString() === "0"){
                    setDialogTitle("NOTICE");
                    setDialogText("Your corporate account has not been fully setup. Contact us on customercare@tabiri.ai to resolve this within 24hours");
                    setAlertDialogStatus(true);


                }else {

                    localStorage.setItem("token", "loggedin");
                    localStorage.setItem("userid", data.user_id.toString());
                    localStorage.setItem("fmcgid", data.fmcgId.toString());
                    setToken(localStorage.getItem("token"));
                    setFmcgId(localStorage.getItem("fmcgid"));
                    setUserId(localStorage.getItem("userid"));
                    setShowAuthentication(false);
                    setShowLoginAlert(false);

                    saveActivity("logged in");
                }
            } else {
                setShowLoginAlert(true)
                setLoginAlertMessage(data.errorMessage);
                //alert(data.errorMessage);
                console.log(data.errorMessage);
            }
        }).catch(function (error) {
            console.log(error);
            setShowLoginAlert(true)
            setLoginAlertMessage(error);
            // alert(error)
        })
    };

    const saveActivity = (action)=>{
        if(localStorage.getItem("userid") !== null) {
            const requestData = {ml_user_id: localStorage.getItem("userid"), activity: action}

            axios.post("https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlactivities", requestData).then(function (response) {

                console.log(response);

            }).catch(function (error) {
                console.log(error);
            });

        }
    }
    const registerUser = (formData) => {
        const requestData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            organization: formData.organization,
            role: formData.role,
            password: formData.password
        }
        setShowRegistrationLoader(true);
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mluserregistration', requestData).then(function (response) {
            console.log(response);

            setShowRegistrationLoader(false);

            const data = response.data;

            if (data.statusCode === "200") {

                setRegistrationAlertMessage(data.body);
                setShowRegistrationAlert(true);
                setShowRegistrationAlertType("success");
                // alert(data.body);

            } else {

                setRegistrationAlertMessage(data.body);
                setShowRegistrationAlert(true);
                setShowRegistrationAlertType("error");
                // alert(data.body);
            }
        }).catch(function (error) {
            console.log(error);
            setRegistrationAlertMessage(error);
            setShowRegistrationAlert(true);
            setShowRegistrationAlertType("error");
        })
    };

    const getPricinggList = (key)=>{
        if(localStorage.getItem(key) !== null ){

            return JSON.parse(localStorage.getItem(key));
        }else{

        return [];
        }


    }

    //get current bleach prices

    useEffect(() => {
        //alert(selectedSubCategory);

        if(selectedSubCategory !=="" && selectedSubCategory!==null && selectedSubCategory !== undefined) {
            if (parseInt(fmcgid) === RECKITT_FMCG_ID) {
                if (selectedSubCategory === "Regular Bleach" && localStorage.getItem(BLEACHES_CURRENT_PRICES_STORAGE_KEY) !== null) {
                    let previousMilliSeconds = parseFloat(localStorage.getItem(PRICELIST_MILLISECONDS_KEY));
                    let currentMilliseconds = parseFloat(new Date().getTime().toFixed(0));
                    if ((currentMilliseconds - previousMilliSeconds) <= REFRESH_PRICELIST_DURATION) {
                        setCurrentPricesList(JSON.parse(localStorage.getItem(BLEACHES_CURRENT_PRICES_STORAGE_KEY)))

                    } else {
                        getCurrentPrice(selectedSubCategory);
                    }

                } else if (selectedSubCategory === "Liquid Toilet Cleaner" && localStorage.getItem(TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY) !== null) {

                    let previousMilliSeconds = parseFloat(localStorage.getItem(PRICELIST_MILLISECONDS_KEY));
                    let currentMilliseconds = parseFloat(new Date().getTime().toFixed(0));
                    if ((currentMilliseconds - previousMilliSeconds) <= REFRESH_PRICELIST_DURATION) {
                        setCurrentPricesList(JSON.parse(localStorage.getItem(TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY)))

                    } else {
                        getCurrentPrice(selectedSubCategory);

                    }
                } else {
                    getCurrentPrice(selectedSubCategory);
                }
            } else {
                //kimberly clarks
                if (selectedSubCategory === "Regular Open Diapers" && localStorage.getItem(DIAPERS_CURRENT_PRICES_STORAGE_KEY) !== null) {
                    let previousMilliSeconds = parseFloat(localStorage.getItem(PRICELIST_MILLISECONDS_KEY));
                    let currentMilliseconds = parseFloat(new Date().getTime().toFixed(0));
                    if ((currentMilliseconds - previousMilliSeconds) <= REFRESH_PRICELIST_DURATION) {
                        setCurrentPricesList(JSON.parse(localStorage.getItem(DIAPERS_CURRENT_PRICES_STORAGE_KEY)))

                    } else {
                        getCurrentPrice(selectedSubCategory);
                    }
                } else {

                    getCurrentPrice(selectedSubCategory);
                }

            }
        }

    }, [selectedSubCategory]);


    const getCurrentPrice =  (subCategory)=>{
        let requestData;
        if(parseInt(fmcgid) === RECKITT_FMCG_ID) {
            if (subCategory === "Regular Bleach") {
                requestData = {fmcgId: fmcgid, category: "Bleach", subCategory: "Regular Bleach"}
            } else {
                requestData = {fmcgId: fmcgid, category: "Toilet Cleaner", subCategory: "Liquid Toilet Cleaner"}
            }
        }else{

            requestData = {fmcgId: fmcgid, category: "Diapers", subCategory: "Regular Open Diapers"}
        }
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachescurrentprices', requestData).then(function (response) {
            console.log(response);

            const data = response.data;
            let result =  data.currentPricesResponseItemsList
            //got current prices successfully
            if (data.statusCode === "200") {

                if(parseInt(fmcgid) === RECKITT_FMCG_ID) {
                    if (requestData.category === "Bleach") {
                        localStorage.setItem(BLEACHES_CURRENT_PRICES_STORAGE_KEY, JSON.stringify(result));
                    } else {
                        localStorage.setItem(TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY, JSON.stringify(result));

                    }
                }else{
                  //kimberly clarks
                    localStorage.setItem(DIAPERS_CURRENT_PRICES_STORAGE_KEY, JSON.stringify(result));
                }
                localStorage.setItem(PRICELIST_MILLISECONDS_KEY,new Date().getTime().toFixed(0));

                //alert("successfully got current prices");
                setCurrentPricesList(result);


            } else {

                alert(data.statusMessage);

            }


        }).catch(function (error) {
            console.log(error);
        });

    }

    const handleShowScenarioStateChange = (newState) => {
        setScenario(newState)

        saveActivity("click scenarios")
    };
    const handleShowMyPlanStateChange = (newState)=>{
        setShowMyPlan(newState);

        saveActivity("click my plans")
    }

    const handleShowSettingsStateChange = (newState)=>{

        setShowSettings(newState);
        saveActivity("click my settings")
    }

    const handleAutomateStateChange = (newState) => {

        if(showAutomateResults){
            setShowAutomateResults(false);
        }
        setShowAutomate(newState);

        saveActivity("click optimize")

    }

    const resetWhatIfChart = (resetChart = true)=>{
        //setDiscount(0);
      //  setCurrentPrice(0);
       // setProposedPrice(0);
       // setProduct("select");
        if(resetChart) {
            setDiscount(0);
            setAbsoluteDiscount(0);
              setCurrentPrice(0);
             setProposedPrice(0);
             setProduct("select");
            setLineChartXAxis([]);
            setLineChartYAxis([]);
        }

        }

    const handleSetActiveLink = (value)=>{
        setActiveLink(value);

        if(value === 0){

         // resetWhatIfChart();
        }
    }

    const handleLogoutStateChange = () => {
        saveActivity("logged out");
        localStorage.removeItem("token");
        localStorage.removeItem("userid");
        localStorage.removeItem(ML_TARGETS_STORAGE_KEY);
        localStorage.removeItem(MLTARGETS_MILLISECONDS_KEY);
        localStorage.removeItem(PRICELIST_MILLISECONDS_KEY);
        localStorage.removeItem(TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY);
        localStorage.removeItem(BLEACHES_CURRENT_PRICES_STORAGE_KEY);
        localStorage.removeItem(DIAPERS_CURRENT_PRICES_STORAGE_KEY);

        setToken(localStorage.getItem("token"));
        setLineChartXAxis([]);
        setLineChartYAxis([]);
        setShowAuthentication(true);
        setShowLoginScreen(true);
        setShowRegistrationScreen(false);
        setShowLandingPage(true)
        setShowMyPlan(false);
        setShowSettings(false);


    };

    const handleLoginButtonLandingPage = ()=>{

        setShowLandingPage(false)
        setShowLoginScreen(true);
        setShowRegistrationScreen(false);
        setShowAuthentication(true);
    };

    const handleLoginBackPress = () => {


        setShowAuthentication(false);
        setShowLoginScreen(false);
        setShowRegistrationScreen(false);
        setShowLandingPage(true)
    };


    const handleTextBoxStateChange = (newState) => {
        if (newState.type === "discount") {
            //setDiscount(parseInt(newState.value))

            if (!isNaN(parseFloat(newState.value))) {

                if(parseFloat(newState.value)  === 0) {
                    console.log("discount is zero")
                }else if(parseFloat(newState.value) > currentPrice){
                    alert("maaximum discount is "+currentPrice);

                }else {
                    if (newState.value.endsWith(".")) {
                        // alert(newState.value);
                        // setDiscount(parseFloat(`${newState.value}0`));

                        let discount = parseFloat(((((currentPrice - (currentPrice - parseFloat(`${newState.value}0`))) / currentPrice))*100).toFixed(2));
                        //setDiscount(parseFloat(newState.value));
                        setDiscount(discount)
                    } else {
                        let discount = parseFloat(((((currentPrice -(currentPrice - parseFloat(`${newState.value}`))) / currentPrice))*100).toFixed(2));

                        //setDiscount(parseFloat(newState.value));
                        setDiscount(discount)
                    }

                     setAbsoluteDiscount(parseFloat(newState.value))
                    let proposedDiscount = (currentPrice - parseFloat(newState.value));
                    setProposedPrice(proposedDiscount);
                }
               // let proposedDiscount = (currentPrice - ((parseFloat(newState.value) / 100) * currentPrice)).toFixed(0);
              //  setProposedPrice(proposedDiscount);
            } else {
               // alert("ok");
               // setProposedPrice(0);
                setProposedPrice(currentPrice);
                setDiscount(0);
                setAbsoluteDiscount(0);
            }
        }else if(newState.type === "price"){

            if(!isNaN(parseFloat(newState.value))){
                let price  = parseFloat(newState.value);

                setCurrentPrice(price)
                setProposedPrice(price);
                setDiscount(0)
                setAbsoluteDiscount(0);

            }else{
                if(newState.value === ""){
                    setCurrentPrice(0)
                    setProposedPrice(0);
                    setDiscount(0);
                    setAbsoluteDiscount(0);
                }else {
                    // setProposedPrice(0);
                    setCurrentPrice(currentPriceOriginal);
                    setProposedPrice(currentPriceOriginal);
                    setDiscount(0);
                    setAbsoluteDiscount(0);
                }
            }


        }else if(newState.type === "maxconstraint"){

            if(constraint === "Max Discount"){
                if(parseFloat(newState.value)> MAX_OPTIMIZE_DISCOUNT){
                    console.log(`max discount is ${MAX_OPTIMIZE_DISCOUNT}%`);
                    setMaxConstraint(parseFloat(newState.value));
                }else{
                    setMaxConstraint(parseFloat(newState.value));
                }

            }else {


                setMaxConstraint(parseFloat(newState.value));
            }

        }

    }
    const handleDropDownStateChange = (newState) => {

        if (newState.type === "product") {
            if (!newState.showImage) {
                setImageUrl(newState.img_url);
                setShowPlaceHolderProductLabel(false);
            } else {
                //show placeholder
                setPlaceHolderProductLabel(newState.text);
                setShowPlaceHolderProductLabel(true);
            }
           // alert(newState.value + " "+currentPricesList.length);

            // alert(newState.img_url);
            //if current prices list has been set
            if (currentPricesList.length > 0) {
               // alert(currentPricesList.length)
                let filterResults = [];

                let filterResultsByProductName = currentPricesList.filter((value, index, array) => {


                    return (value.product === newState.value) && (value.retailer_id === retailer);
                });

                if(filterResultsByProductName.length > 0){

                    filterResults = filterResultsByProductName;

                }else{

                    let filterResultsByBarcode = currentPricesList.filter((value, index, array) => {


                        return (value.barcode === newState.barcode) && (value.retailer_id === retailer);
                    });

                    if(filterResultsByBarcode.length > 0){

                        filterResults = filterResultsByBarcode;

                    }

                }

                if (filterResults.length > 0) {

                    // alert(filterResults[0].price);
                  //check if is value pack

                        setCurrentPrice(filterResults[0].price);
                        setCurrentPriceOriginal(filterResults[0].price);
                        setDiscount(0);
                        setAbsoluteDiscount(0);
                        setProduct(newState.value);


                    if(newState.selectedProductIndex >= 1){

                        setProductIndex(newState.selectedProductIndex - 1);

                    }else {
                        setProductIndex(newState.selectedProductIndex);
                    }
                    //check if proposed discount has been set
                    setProposedPrice(filterResults[0].price);
                    

                } else {

                    if(newState.value.includes("Harpic")){
//alert(newState.valueOf() + " "+newState.barcode);
                        setCurrentPrice(600);
                        setCurrentPriceOriginal(600);
                        setDiscount(0);
                        setAbsoluteDiscount(0);

                        setProduct(newState.value);
                        if(newState.selectedProductIndex >= 1){

                            setProductIndex(newState.selectedProductIndex - 1);

                        }else {
                            setProductIndex(newState.selectedProductIndex);
                        }
                        //check if proposed discount has been set
                        setProposedPrice(600);
                       //setEffectivePrice(600);

                    }else {
                        setCurrentPrice(0);
                        setCurrentPriceOriginal(0)
                        setProposedPrice(0);
                        setDiscount(0);
                        setAbsoluteDiscount(0);
                        setProductIndex(0);
                        alert("current price not found for product");
                    }
                }


            }

            setBarcode(newState.barcode);
            setLineChartYAxis([]);
            setLineChartXAxis([]);
            setLineChartYAxisPredicted([]);

            //setCurrentPrice(newState.type);
            if(date === DEFAULT_PREVIOUS_MONTH_DATE||date === DEFAULT_CURRENT_MONTH_DATE){

                setDisableLockPrediction(false);


                    checkLockedPredictionAvailability(date,newState.value,category,subcategory,retailer,selecteModelId);



            }else{
                setDisableLockPrediction(true);
                setShowLockedPredictionButton(false);
                setLockedPredictionValues([]);
            }
        } else if (newState.type === "discount") {

            setDiscount(parseInt(newState.value))


        } else if (newState.type === "periodlevers") {

            setPeriod(parseInt(newState.value));

            //alert(newState.value);

        } else if (newState.type === "subcategory") {

            // setSubCategory(newState.value)

             //alert(newState.value);
setLineChartYAxis([]);
setLineChartXAxis([]);
setImageUrl("");

if(parseInt(fmcgid) === RECKITT_FMCG_ID) {
    if (newState.value === "Regular Bleach") {
        let retailersInputs = [];
        setProduct("Jik Bleach Regular 750ml")
        setCategory("Bleach")
        setSubCategory(newState.value)
        setBrand("Jik")
        setSize("750ml")
        setSelectedSubCategory(newState.value)
        setProductIndex(1);

        if (retailer === NAIVAS_RETAILER_ID) {
            setSelectedModelId(NAIVAS_BLEACHES_MODEL_ID);
        } else {
            setSelectedModelId(CARREFOUR_BLEACHES_MODEL_ID);
        }

        if (selectedRetailers.current.length === 1) {
            //naivas
            if (selectedRetailers.current[0].retailerName === "Naivas") {

                retailersInputs = [{
                    retailerId: NAIVAS_RETAILER_ID,
                    retailerName: "Naivas",
                    modelId: NAIVAS_BLEACHES_MODEL_ID
                }]
                //carrefour
            } else {
                retailersInputs = [{
                    retailerId: CARREFOUR_RETAILER_ID,
                    retailerName: "Carrefour",
                    modelId: CARREFOUR_BLEACHES_MODEL_ID
                }]

            }

        } else if (selectedRetailers.current.length === 2) {
            retailersInputs = [
                {
                    retailerId: NAIVAS_RETAILER_ID,
                    retailerName: "Naivas",
                    modelId: NAIVAS_BLEACHES_MODEL_ID
                },
                {
                    retailerId: CARREFOUR_RETAILER_ID,
                    retailerName: "Carrefour",
                    modelId: CARREFOUR_BLEACHES_MODEL_ID
                }
            ]

        }


        let value;
        if (getPricinggList(BLEACHES_CURRENT_PRICES_STORAGE_KEY).length === 0) {
            value = getFilteredPrice("Jik Bleach Regular 750ml", retailer)

        } else {
            let pricingList = getPricinggList(BLEACHES_CURRENT_PRICES_STORAGE_KEY);
            //alert(pricingList.length);
            value = getFilteredPriceCustomList(pricingList, "Jik Bleach Regular 750ml", retailer, "Bleach");

        }

        setCurrentPrice(value);
        setCurrentPriceOriginal(value);
        setProposedPrice(value);
        setDiscount(0);
        setAbsoluteDiscount(0);
        selectedRetailers.current = retailersInputs;


        // alert(getCurrentPrice);
        if (date === DEFAULT_PREVIOUS_MONTH_DATE || date === DEFAULT_CURRENT_MONTH_DATE) {

            setDisableLockPrediction(false);


            checkLockedPredictionAvailability(date, "Jik Bleach Regular 750ml", "Bleach", newState.value, retailer, selecteModelId);


        } else {
            setDisableLockPrediction(true);
            setShowLockedPredictionButton(false);
            setLockedPredictionValues([]);
        }

        //toilet cleaners
    } else {
        let retailersInputs = [];
        // alert(newState.value);
        setProduct("Harpic Power Plus Citrus 500ml")
        setCategory("Toilet Cleaner")
        setSubCategory("Liquid Toilet Cleaner")
        setBrand("Harpic")
        setSize("500ml")
        setSelectedSubCategory("Liquid Toilet Cleaner")
        setProductIndex(1);
        if (retailer === NAIVAS_RETAILER_ID) {
            setSelectedModelId(NAIVAS_TOILETCLEANERS_MODEL_ID);
        } else {
            setSelectedModelId(CARREFOUR_TOILETCLEANERS_MODEL_ID);
        }

        if (selectedRetailers.current.length === 1) {
            //naivas
            if (selectedRetailers.current[0].retailerName === "Naivas") {
                retailersInputs = [
                    {
                        retailerId: NAIVAS_RETAILER_ID,
                        retailerName: "Naivas",
                        modelId: NAIVAS_TOILETCLEANERS_MODEL_ID
                    },
                ]
                //carrefour
            } else {

                retailersInputs = [
                    {
                        retailerId: CARREFOUR_RETAILER_ID,
                        retailerName: "Carrefour",
                        modelId: CARREFOUR_TOILETCLEANERS_MODEL_ID
                    }
                ]
            }

        } else if (selectedRetailers.current.length === 2) {
            retailersInputs = [
                {
                    retailerId: NAIVAS_RETAILER_ID,
                    retailerName: "Naivas",
                    modelId: NAIVAS_TOILETCLEANERS_MODEL_ID
                },
                {
                    retailerId: CARREFOUR_RETAILER_ID,
                    retailerName: "Carrefour",
                    modelId: CARREFOUR_TOILETCLEANERS_MODEL_ID
                }
            ]

        }


        let value;
        if (getPricinggList(TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY).length === 0) {
            value = getFilteredPrice("Harpic Power Plus Citrus 500ml", retailer)

        } else {

            let pricingList = getPricinggList(TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY);
            // alert(pricingList.length);
            value = getFilteredPriceCustomList(pricingList, "Harpic Power Plus Citrus 500ml", retailer, "Toilet Cleaner");
//alert(retailer);
        }

        setCurrentPrice(value);
        setCurrentPriceOriginal(value);
        setProposedPrice(value);
        setDiscount(0);
        setAbsoluteDiscount(0);
        selectedRetailers.current = retailersInputs;

        if (date === DEFAULT_PREVIOUS_MONTH_DATE || date === DEFAULT_CURRENT_MONTH_DATE) {

            setDisableLockPrediction(false);

            checkLockedPredictionAvailability(date, "Harpic Power Plus Citrus 500ml", "Liquid Toilet Cleaner", newState.value, retailer, selecteModelId);


        } else {
            setDisableLockPrediction(true);
            setShowLockedPredictionButton(false);
            setLockedPredictionValues([]);
        }
    }
       }else{
    //Kimberly Clarks




    let retailersInputs = [];
        setProduct("Huggies SC Size 4")
        setCategory("Diapers")
        setSubCategory("Regular Open Diapers")
        setBrand("Huggies")
        setSize("Size 4")
        setSelectedSubCategory("Regular Open Diapers")
        setProductIndex(1);
        setSegment("SC");


            setSelectedModelId(DIAPERS_MODEL_ID);










    }


            //end
        } else if (newState.type === "size") {

            if(parseInt(fmcgid) === RECKITT_FMCG_ID  && selectedSubCategory === "Regular Bleach") {
                  let gotProduct;
                  if(newState.value === "750ml"){
                      gotProduct = "Jik Bleach Regular 750ml";
                  }else if(newState.value === "1500ml") {
                     if(retailer === NAIVAS_RETAILER_ID) {
                         gotProduct = "Jik Bleach Regular 1.5L";
                     }else{
                         gotProduct = "Jik Bleach Regular Perfumed Promo 1.5L";
                     }
                     // alert("ok");
                  }else if(newState.value === "2250ml"){
                      gotProduct = "Jik Bleach Regular 2.25L";

                      //alert("ok");
                  }
                    setProduct(gotProduct)
                    setCategory("Bleach")
                    setSubCategory("Regular Bleach")
                    setBrand("Jik")
                    setSize(newState.value)
                    setSelectedSubCategory("Regular Bleach")
                    setProductIndex(1);


                let value;
                if (getPricinggList(BLEACHES_CURRENT_PRICES_STORAGE_KEY).length === 0) {
                    value = getFilteredPrice(gotProduct, retailer)

                } else {
                    let pricingList = getPricinggList(BLEACHES_CURRENT_PRICES_STORAGE_KEY);
                    //alert(pricingList.length);
                    value = getFilteredPriceCustomList(pricingList, gotProduct, retailer, "Bleach");

                }

                setCurrentPrice(value);
                setCurrentPriceOriginal(value);
                setProposedPrice(value);
                setDiscount(0);
                setAbsoluteDiscount(0);
                 // alert(product)

                //clear chart
                setLineChartYAxis([]);
                setLineChartXAxis([]);
                setImageUrl("");
                }else{
                setSize(newState.value);
            }






/**
            const regex = new RegExp(`\\b${segment} ${newState.value}\\b`, 'gi');

            // Filter products based on the regex
            const filtered = currentPricesList.filter(value => value.product.match(regex) && value.retailer_id === retailer);

            let uniqueFiltered = Array.from(new Set(filtered));
            console.log(uniqueFiltered);

            const mappedProducts = uniqueFiltered.map(value => ({
                productName: value.product,
                price: value.price
              }));

            setFilteredDiaperProducts(mappedProducts);
 **/
            // console.log(getDiapersOptimizeProducts());

        } else if (newState.type === "brand") {

            setBrand(newState.value);

        } else if (newState.type === "segment") {

            setSegment(newState.value);


        }else if(newState.type === "constraints"){

            setConstraint(newState.value);


        }else if(newState.type === "objective") {

            setObjective(newState.value);
        }else if(newState.type === "multipleretailers"){

            try {

                /**
                 selectedRetailers:[
                 {
                    retailerId: 3,
                    retailerName: "Naivas"
                },
                 {
                    retailerId: 30,
                    retailerName: "Carrefour"
                }
                 ]
                 **/
                let value = newState.value;
                let selectedRetailerss;

                if (value === undefined || value === null || value === "") {
                    selectedRetailerss = [];

                    alert(newState.value + "empty");
                } else {
                    if (isArray(value)) {

                        if (value.length === 1) {
                            if (value[0] === "Naivas") {

                                if(category === "Bleach") {
                                    selectedRetailerss = [
                                        {
                                            retailerId: NAIVAS_RETAILER_ID,
                                            retailerName: "Naivas",
                                            modelId: NAIVAS_BLEACHES_MODEL_ID,
                                            portfolioBleachesModelId : NAIVAS_BLEACHES_MODEL_ID,
                                            portfolioToiletCleanersModelId : NAIVAS_TOILETCLEANERS_MODEL_ID
                                        }

                                    ]

                                    setRetailer(NAIVAS_RETAILER_ID);
                                    setRetailerName("Naivas")
                                    setSelectedModelId(NAIVAS_BLEACHES_MODEL_ID)
                                    //toilet cleaners
                                }
                                else if (category === "Diapers")
                                {
                                    selectedRetailerss = [
                                        {
                                            retailerId: NAIVAS_RETAILER_ID,
                                            retailerName: "Naivas",
                                            modelId: DIAPERS_MODEL_ID
                                        }

                                    ]

                                    setRetailer(NAIVAS_RETAILER_ID);
                                    setRetailerName("Naivas")
                                    setSelectedModelId(DIAPERS_MODEL_ID)
                                }
                                else{



                                    selectedRetailerss = [
                                        {
                                            retailerId: NAIVAS_RETAILER_ID,
                                            retailerName: "Naivas",
                                            modelId: NAIVAS_TOILETCLEANERS_MODEL_ID,
                                            portfolioBleachesModelId : NAIVAS_BLEACHES_MODEL_ID,
                                            portfolioToiletCleanersModelId : NAIVAS_TOILETCLEANERS_MODEL_ID
                                        }

                                    ]

                                    setRetailer(NAIVAS_RETAILER_ID);
                                    setRetailerName("Naivas")
                                    setSelectedModelId(NAIVAS_TOILETCLEANERS_MODEL_ID)

                                }

//carrefour
                            } else {

                                if(category === "Bleach") {

                                    selectedRetailerss = [{
                                        retailerId: CARREFOUR_RETAILER_ID,
                                        retailerName: "Carrefour",
                                        modelId: CARREFOUR_BLEACHES_MODEL_ID,
                                        portfolioBleachesModelId : CARREFOUR_BLEACHES_MODEL_ID,
                                        portfolioToiletCleanersModelId : CARREFOUR_TOILETCLEANERS_MODEL_ID
                                    }]

                                    setRetailer(CARREFOUR_RETAILER_ID);
                                    setRetailerName("Carrefour")
                                    setSelectedModelId(CARREFOUR_BLEACHES_MODEL_ID)
                                }
                                else if (category === "Diapers")
                                {
                                    selectedRetailerss = [
                                        {
                                            retailerId: CARREFOUR_RETAILER_ID,
                                            retailerName: "Carrefour",
                                            modelId: DIAPERS_MODEL_ID
                                        }

                                    ]

                                    setRetailer(CARREFOUR_RETAILER_ID);
                                    setRetailerName("Carrefour")
                                    setSelectedModelId(DIAPERS_MODEL_ID)
                                }
                                else{

                                    selectedRetailerss = [{
                                        retailerId: CARREFOUR_RETAILER_ID,
                                        retailerName: "Carrefour",
                                        modelId: CARREFOUR_TOILETCLEANERS_MODEL_ID,
                                        portfolioBleachesModelId : CARREFOUR_BLEACHES_MODEL_ID,
                                        portfolioToiletCleanersModelId : CARREFOUR_TOILETCLEANERS_MODEL_ID
                                    }]

                                    setRetailer(CARREFOUR_RETAILER_ID);
                                    setRetailerName("Carrefour")
                                    setSelectedModelId(CARREFOUR_TOILETCLEANERS_MODEL_ID)


                                }

                            }
                            //  alert(newState.value + " array 1");

                        } else if (value.length === 2) {
                            if(category === "Bleach") {
                                selectedRetailerss = [
                                    {
                                        retailerId: NAIVAS_RETAILER_ID,
                                        retailerName: "Naivas",
                                        modelId: NAIVAS_BLEACHES_MODEL_ID,
                                        portfolioBleachesModelId : NAIVAS_BLEACHES_MODEL_ID,
                                        portfolioToiletCleanersModelId : NAIVAS_TOILETCLEANERS_MODEL_ID
                                    },
                                    {
                                        retailerId: CARREFOUR_RETAILER_ID,
                                        retailerName: "Carrefour",
                                        modelId: CARREFOUR_BLEACHES_MODEL_ID,
                                        portfolioBleachesModelId : CARREFOUR_BLEACHES_MODEL_ID,
                                        portfolioToiletCleanersModelId : CARREFOUR_TOILETCLEANERS_MODEL_ID
                                    }
                                ]
                            }
                            else if (category === "Diapers")
                            {
                                selectedRetailerss = [
                                    {
                                        retailerId: NAIVAS_RETAILER_ID,
                                        retailerName: "Naivas",
                                        modelId: DIAPERS_MODEL_ID
                                    },
                                    {
                                        retailerId: CARREFOUR_RETAILER_ID,
                                        retailerName: "Carrefour",
                                        modelId: DIAPERS_MODEL_ID
                                    }

                                ]
                            }
                            else{
                                //toilet cleaners
                                selectedRetailerss = [
                                    {
                                        retailerId: NAIVAS_RETAILER_ID,
                                        retailerName: "Naivas",
                                        modelId: NAIVAS_TOILETCLEANERS_MODEL_ID,
                                        portfolioBleachesModelId : NAIVAS_BLEACHES_MODEL_ID,
                                        portfolioToiletCleanersModelId : NAIVAS_TOILETCLEANERS_MODEL_ID
                                    },
                                    {
                                        retailerId: CARREFOUR_RETAILER_ID,
                                        retailerName: "Carrefour",
                                        modelId: CARREFOUR_TOILETCLEANERS_MODEL_ID,
                                        portfolioBleachesModelId : CARREFOUR_BLEACHES_MODEL_ID,
                                        portfolioToiletCleanersModelId : CARREFOUR_TOILETCLEANERS_MODEL_ID
                                    }
                                ]

                            }
                        } else {
                            selectedRetailerss = [];
                            alert(newState.value + "empty");
                        }

                    } else {
                        selectedRetailerss = [];
                    }
                }

                selectedRetailers.current = selectedRetailerss;
                if(showAutomateResults){
                    setShowAutomateResults(false);
                }

            }catch (e) {
                console.log(e);
                alert(e);
            }




        }else if(newState.type === "retailer"){

            setRetailerName(newState.value);
            let model;

            let retailerid;
            if(parseInt(fmcgid) === RECKITT_FMCG_ID) {
                if (newState.value === "Naivas") {
                    setRetailer(NAIVAS_RETAILER_ID);
                    retailerid = NAIVAS_RETAILER_ID;
                    if (subcategory === "Regular Bleach") {
                        model = NAIVAS_BLEACHES_MODEL_ID;
                        setSelectedModelId(NAIVAS_BLEACHES_MODEL_ID);
                        setSize("750ml");
                    } else {
                        //toilet cleaners
                        setSelectedModelId(NAIVAS_TOILETCLEANERS_MODEL_ID);
                        model = NAIVAS_TOILETCLEANERS_MODEL_ID;
                    }

                } else {
                    setRetailer(CARREFOUR_RETAILER_ID);
                    retailerid = CARREFOUR_RETAILER_ID;
                    if (subcategory === "Regular Bleach") {
                        setSelectedModelId(CARREFOUR_BLEACHES_MODEL_ID);
                        retailerid = CARREFOUR_BLEACHES_MODEL_ID;
                        setSize("750ml")
                    } else {
                        //alert(`${subcategory} data not available for ${retailerName}`);
                        //toilet cleaners
                        retailerid = CARREFOUR_TOILETCLEANERS_MODEL_ID;
                        setSelectedModelId(CARREFOUR_TOILETCLEANERS_MODEL_ID);

                    }

                }
            }else{
                model = DIAPERS_MODEL_ID;
                setSelectedModelId(DIAPERS_MODEL_ID);
                if (newState.value === "Naivas") {
                      retailerid = NAIVAS_RETAILER_ID;
                    setRetailer(NAIVAS_RETAILER_ID);
                    //carrefour
                }else{
                    retailerid = CARREFOUR_RETAILER_ID;
                 setRetailer(CARREFOUR_RETAILER_ID);

                }

            }



            if(date === DEFAULT_PREVIOUS_MONTH_DATE||date === DEFAULT_CURRENT_MONTH_DATE){

                setDisableLockPrediction(false);

                checkLockedPredictionAvailability(date,product,category,subcategory,retailerid,model);

            }else{
                setDisableLockPrediction(true);
                setShowLockedPredictionButton(false);
                setLockedPredictionValues([]);
            }

        } else {

             alert(newState.value);

        }

    }

    const handleDatePickerSelect = (value) => {

        let got_date = `${value}-01`;
        let previous_date = getPreviousMonth(got_date);
        console.log(`selected date ${got_date}`);
        console.log(`previous date ${previous_date}`);

        if(got_date === DEFAULT_PREVIOUS_MONTH_DATE||got_date === DEFAULT_CURRENT_MONTH_DATE){

            setDisableLockPrediction(false);

            checkLockedPredictionAvailability(got_date,product,category,subcategory,retailer,selecteModelId);

        }else{
          setDisableLockPrediction(true);
          setShowLockedPredictionButton(false);
          setLockedPredictionValues([]);
        }
        setDate(got_date);
        setPreviousDate(previous_date);

        //alert(previous_date);


    }

    const getFilteredPriceCustomList = (priceList,productName, gotRetailer,gotCategory, barcode = "none") => {

        const filterResults = priceList.filter((value, index, array) => {


            return (value.product === productName) && (value.retailer_id === gotRetailer);
        });

        if(filterResults.length === 0){
            if(barcode !== "none") {

                const filterResults = priceList.filter((value, index, array) => {


                    return (value.barcode === barcode) && (value.retailer_id === gotRetailer);
                });
                if (filterResults.length === 0) {
                    if(gotCategory.toLowerCase().includes("toilet")) {
                        if (productName.includes("Harpic")) {
                            return 600;
                        } else {
                            return 0;
                        }
                    }else{
                        return 0;
                    }
                } else {
                    return filterResults[0].price;
                }

            }else{
                //alert("0");
                if(gotCategory.toLowerCase().includes("toilet")) {
                    if (productName.includes("Harpic")) {
                        return 600;
                    } else {
                        return 0;
                    }
                }else {
                    return 0;
                }
            }

        }else {
            return filterResults[0].price;
        }
    };


    const getFilteredPrice = (productName, gotRetailer, barcode = "none") => {

        const filterResults = currentPricesList.filter((value, index, array) => {


            return (value.product === productName) && (value.retailer_id === gotRetailer);
        });

        if(filterResults.length === 0){
            if(barcode !== "none") {

                const filterResults = currentPricesList.filter((value, index, array) => {


                    return (value.barcode === barcode) && (value.retailer_id === gotRetailer);
                });
                if (filterResults.length === 0) {
                    if(category.toLowerCase().includes("toilet")) {
                        if (productName.includes("Harpic")) {
                            return 600;
                        } else {
                            return 0;
                        }
                    }else{
                        return 0;
                    }
                } else {
                    return filterResults[0].price;
                }

            }else{
                //alert("0");
                if(category.toLowerCase().includes("toilet")) {
                    if (productName.includes("Harpic")) {
                        return 600;
                    } else {
                      return 0;
                    }
                }else {
                    return 0;
                }
            }

        }else {
            return filterResults[0].price;
        }
    };



    const getLast3MonthAverageSales = (list, productName, barcode = "none" ) => {

        const filterResults = list.filter((value, index, array) => {


            return (value.product === productName || value.product.includes(productName)) ;
        });

        if(filterResults.length === 0){
            if(barcode !== "none") {

                const filterResults = list.filter((value, index, array) => {


                    return (value.barcode === barcode);
                });
                if (filterResults.length === 0) {

                    return 0;
                } else {
                    return filterResults[0].last_3_months_avg_sales_values;
                }

            }else{
                return 0;
            }

        }else {
            return filterResults[0].last_3_months_avg_sales_values;
        }
    };


    const getDiapersOptimizeProducts =()=>{

        let brandNames = ["Pampers","Molfix","Softcare","Huggies"];

        let optimizeProducts = [];
        brandNames.forEach((value)=>{
            optimizeProducts.push({productName:`${value} ${segment} ${size}`, price: getFilteredPrice(`${value} ${segment} ${size}`, retailer)})

        });

        return optimizeProducts;








    }


    const handleOptimizeButtonClicked = () => {

       // alert(selectedRetailers.current);
        if(selectedRetailers.current.length === 0) {

            /**
             setDialogTitle("NOTICE");
             setDialogText("Please select a retailer");
             setAlertDialogStatus(true);
             **/

            alert("Please select a retailer");

        }else if(date === "0"){

        alert("please select date");

        }else  if(constraint.length === 0) {

            alert("please select a constraint max discount or max budget");
        }else if(maxconstraint === 0) {

            alert(`please set the maximum value for ${constraint}`);
        }else if ((constraint === "Max Discount") && (maxconstraint > MAX_OPTIMIZE_DISCOUNT)) {

            alert(`maximum discount that can be set is ${MAX_OPTIMIZE_DISCOUNT}%`);

        }else if ((constraint === "Max Discount") && (selectedRetailers.current.length > 1)) {

            alert("maximum discount can only have one retailer");
        }else {


            if (showAutomateResults) {
                setShowAutomateResults(false);

            }

            //alert(maxconstraint);

            /**
             const requestData = {
            targetDiscount: maxconstraint,
            targetVolume: 0,
            targetROI: 0
        }
             **/

            let selectedConstraint = constraint;

            if (selectedConstraint === "Max Discount") {
                /**
                 const requestData = {
                modelId: 9,
                fmcgId: 34,
                date: date,
                product: product,
                category: category,
                subcategory: subcategory,
                size: size,
                targetPrice: 0,
                effectivePrice: 0,
                discount: maxconstraint,
                period: 3,
                Jik_Bleach_Regular_750ml_price: 430,
                Jik_Bleach_Spring_Fresh_750ml_price: 430,
                Jik_Bleach_Lemon_750ml_price: 430,
                requestType: "optimize",
                constraint: constraint.replaceAll(" ", ""),
                budget: 0
            }
                 **/
                /**
                 * barcode IN ('7171100950021', '6161100953055', '6161100952430', '6161100956827', '6161100956964', '6161100956988', '6164000015144', '6161100047891', 'NULL', '6161101010511', '6161109448507', '8934868135746')
                 * @type {[{price: (number|*), productName: string},{price: (number|*), productName: string},{price: (number|*), productName: string},{price: (number|*), productName: string},{price: (number|*), productName: string},null,null,null,null,null,null,null,null]}
                 */
                const optimizeProducts = [
                { productName: 'Harpic Power Plus Citrus 500ml', price: getFilteredPrice('Harpic Power Plus Citrus 500ml', retailer, '7171100950021') },
                { productName: 'Harpic Power Plus Lavender 500ml', price: getFilteredPrice('Harpic Power Plus Lavender 500ml', retailer, '6161100953055') },
                { productName: 'Harpic Power Plus Original 500ml', price: getFilteredPrice('Harpic Power Plus Original 500ml', retailer, '6161100952430') },
                { productName: 'Harpic Toilet Cleaner Mountain Pine 500ml', price: getFilteredPrice('Harpic Toilet Cleaner Mountain Pine 500ml', retailer, '6161100956827') },
                { productName: 'Harpic Toilet Cleaner P/P Flo 500ml', price: getFilteredPrice('Harpic Toilet Cleaner P/P Flo 500ml', retailer, '6161100956964') },
                { productName: 'Harpic Toilet Cleaner P/P Lem 500ml', price: getFilteredPrice('Harpic Toilet Cleaner P/P Lem 500ml', retailer, '6161100956988') },
                { productName: 'Hurricane 500ml', price: getFilteredPrice('Hurricane 500ml', retailer,'6164000015144') },
                { productName: 'Ace 500ml', price: getFilteredPrice('Ace 500ml', retailer, '6161100047891') },
                { productName: 'Atilla 500ml', price: getFilteredPrice('Atilla 500ml', retailer,'NAI-13002071') },
                { productName: 'Tropikal 500ml', price: getFilteredPrice('Tropikal 500ml', retailer, '6161101010511') },
                { productName: 'Safisha 500ml', price: getFilteredPrice('Safisha 500ml', retailer, '6161109448507') },
                { productName: 'Domestos 500ml', price: getFilteredPrice('Domestos 500ml', retailer, '8934868135746') },
                { productName: 'Velvex 500ml', price: getFilteredPrice('Velvex 500ml', retailer,'6161100761520') },
                { productName: 'Harpic Power Plus Original 2x500ml', price: getFilteredPrice('Harpic Power Plus Original 2x500ml', retailer, '6161100954199')}

                ];

                if (category === "Diapers")
                {
                    setFilteredDiaperProducts(getDiapersOptimizeProducts());
                }

                const requestData = {
                    modelId: selecteModelId,
                    fmcgId: fmcgid,
                    retailerId: retailer,
                    date: date,
                    product: product,
                    category: category,
                    subcategory: subcategory,
                    size: size,
                    targetPrice: currentPriceOriginal,
                    effectivePrice: currentPriceOriginal,
                    currentPrice:currentPriceOriginal,
                    userId:userId,
                    discount: maxconstraint,
                    period: 3,
                    Jik_Bleach_Regular_750ml_price: getFilteredPrice("Jik Bleach Regular 750ml", retailer),
                    Jik_Bleach_Spring_Fresh_750ml_price: getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer),
                    Jik_Bleach_Lemon_750ml_price: getFilteredPrice("Jik Bleach Lemon 750ml", retailer),
                    requestType: "optimize",
                    constraint: constraint.replaceAll(" ", ""),
                    objective: objective,
                    budget: 0,
                    Omo_Bleach_Lemon_700ml_price: getFilteredPrice("Omo Bleach Lemon Fresh 700ml", retailer),
                    Omo_Bleach_Regular_700ml_price: getFilteredPrice("Omo Bleach Regular 700ml", retailer),
                    Safisha_Bleach_Lemon_750ml_price: getFilteredPrice("Safisha Bleach Lemon 750ml", retailer),
                    Safisha_Bleach_Regular_750ml_price: getFilteredPrice("Safisha Bleach Regular 750ml", retailer),
                    Topex_Bleach_Lemon_750ml_price: getFilteredPrice("Topex Bleach Lemon 750ml", retailer),
                    Topex_Bleach_Regular_750ml_price: getFilteredPrice("Topex Bleach Regular 750ml", retailer),
                    Msafi_Bleach_Lavender_750ml_price: getFilteredPrice("Msafi Bleach Lavender 750ml", retailer),
                    Msafi_Bleach_Lemon_Fresh_750ml_price: getFilteredPrice("Msafi Bleach Lemon Fresh 750ml", retailer),
                    Msafi_Bleach_Potpourri_750ml_price: getFilteredPrice("Msafi Bleach Potpourri 750ml", retailer),
                    Msafi_Bleach_Regular_750ml_price: getFilteredPrice("Msafi Bleach Regular 750ml", retailer),
                    brand: brand,
                    selectedRetailers: selectedRetailers.current,
                    optimizeProducts: (category === "Diapers") ? getDiapersOptimizeProducts() : optimizeProducts,
                    JikBleachRegular2plus1PromoPack3x750mlPrice: getFilteredPrice("Jik Bleach Regular 2+1 Promo Pack 3x750ml", retailer),
                    segment: segment
                    /**[
                     {
                        retailerId: 3,
                        retailerName: "Naivas"
                    },
                     {
                        retailerId: 30,
                        retailerName: "Carrefour"
                    }
                     ]
                     **/

                    /**
                     * "optimizeProducts": {"type": "array",
                     *         "items": {"type": "object",
                     *             "properties": {
                     *            "productName": {
                     *             "type": "string"
                     *           },
                     *           "price": {
                     *             "type": "number"
                     *           }
                     *             }
                     *         }
                     *         }
                     */

                }

                setShowAutomateLoader(true);
                axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {
                    console.log(response);

                    // setShowLoginLoader(false);

                    const data = response.data;

                    if (data === null || data === "") {
                        console.log("Data is null")
                        alert("Data is null");
                    } else {

                        console.log(data.volumeResultList);

                        let retailerNameResult;
                        for (let retailerIndex = 0; retailerIndex < data.volumeResultList.length; retailerIndex++) {
                            retailerNameResult = data.volumeResultList[retailerIndex].retailerName;
                            maxDiscount.current = data.volumeResultList[retailerIndex].volumeResponse;
                            let gotRetailerId = retailerNameResult === "Naivas"?NAIVAS_RETAILER_ID:CARREFOUR_RETAILER_ID;
                            let gotModelId;
                            if(category === 'Bleach') {
                                gotModelId = gotRetailerId === NAIVAS_RETAILER_ID ? NAIVAS_BLEACHES_MODEL_ID : CARREFOUR_BLEACHES_MODEL_ID;
                            }
                            else if(category === "Diapers")
                            {
                                gotModelId = gotRetailerId === NAIVAS_RETAILER_ID ? DIAPERS_MODEL_ID : DIAPERS_MODEL_ID;
                            }
                            else{
                                gotModelId = gotRetailerId === NAIVAS_RETAILER_ID ? NAIVAS_TOILETCLEANERS_MODEL_ID : CARREFOUR_TOILETCLEANERS_MODEL_ID;
                            }

                            let averagePriceAndQuantityUrl;

                            if(category === "Diapers")
                            {
                                averagePriceAndQuantityUrl = `https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}&category=${category}&subcategory=${subcategory}&date=${date}&segment=${segment}&size=${size}`;
                            }
                            else
                            {
                                averagePriceAndQuantityUrl = `https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}&category=${category}&subcategory=${subcategory}&date=${date}&size=${size}`;
                            }

                            axios.get(averagePriceAndQuantityUrl).then(function (response) {
                                console.log(response);

                                // setShowLoginLoader(false);

                                const data = response.data;

                                if (data === null || data === "") {
                                    console.log("Data is null")

                                    alert("Data is null");

                                } else {
                                    console.log(data);
                                    averagePriceAndQuantity.current = data;

                                    //let budget = maxconstraint;

                                    // Initialize an empty array to store JSON objects
                                    var jsonArray = [];
                                    let isFinished = false;
                                    for (let i = 0; i < maxDiscount.current.length; i++) {
                                        if (!isFinished) {
                                            var roiArray = [];
                                            var salesGrowthArray = [];
                                            var investmentArray = [];
                                            var predictedQtyArray = [];
                                            var predictedPriceArray = [];
                                            var previousQtyArray = [];
                                            var previousPriceArray = [];
                                            var upliftArray = [];
                                            for (let j = 0; j < averagePriceAndQuantity.current.length; j++) {
                                                console.log("Discount Index: " + i + " product Index: " + j);
                                                let predictedQty = maxDiscount.current[i].output[0][j]; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? maxDiscount.current[i].output[0][j] : 0;
                                               /**
                                                let predictedPrice;
                                                if(category === 'Bleach') {
                                                    predictedPrice = 430 * (1 - i / 100);
                                                }else{
                                                    predictedPrice = 600 * (1 - i / 100);
                                                }

                                                let salesValue = averagePriceAndQuantity.current[j].averageSalesValue;
                                                let previousQty = averagePriceAndQuantity.current[j].previousQuantity; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averageSalesQuantity;
                                                let previousPrice = averagePriceAndQuantity.current[j].averagePrice; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averagePrice : 0;

                                                let ROI = parseFloat(calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice));
                                                let salesGrowth = (predictedQty * predictedPrice) - (previousQty * previousPrice);
                                                let investment = -(predictedQty * (predictedPrice - previousPrice));
                                                let uplift = calculateUplift(predictedQty, previousQty); //calculateValueUplift(predictedPrice, previousQty);
**/

                                               let predictedPrice;
                                                let tempCurrentPrice;

                                                if (category === 'Bleach') {
                                                    predictedPrice = 430 * (1 - i / 100);
                                                    tempCurrentPrice = 430;
                                                }
                                                else if (category === 'Diapers')
                                                {
                                                    predictedPrice = 1019 * (1 - i / 100);
                                                    tempCurrentPrice = 1019;    
                                                } 
                                                else {
                                                    predictedPrice = 600 * (1 - i / 100);
                                                    tempCurrentPrice = 600;
                                                }

                                                let lastThreeMonthSales = averagePriceAndQuantity.current[j].averageSalesValue;
                                                let previousQty = averagePriceAndQuantity.current[j].previousQuantity;
                                                let previousPrice = averagePriceAndQuantity.current[j].averagePrice;

                                                let ROI = calculateSalesROiOptimize(predictedQty, predictedPrice, previousQty, tempCurrentPrice, lastThreeMonthSales);
                                                let salesGrowth = ((predictedQty * predictedPrice) - lastThreeMonthSales);
                                                let investment = ((tempCurrentPrice - predictedPrice) * predictedQty);
                                                let uplift = calculateValueUplift((predictedPrice * predictedQty), lastThreeMonthSales);

                                                roiArray.push(ROI);
                                                predictedQtyArray.push(predictedQty);
                                                predictedPriceArray.push(predictedPrice);
                                                previousQtyArray.push(previousQty);
                                                previousPriceArray.push(previousPrice);
                                                salesGrowthArray.push(salesGrowth);
                                                investmentArray.push(investment);
                                                upliftArray.push(uplift);
                                            }

                                            // Create a JSON object for each data entry
                                            var jsonObject = {
                                                "Retailer":retailerNameResult,
                                                "Discount": i,
                                                "predictedQty": predictedQtyArray,
                                                "predictedPrice": predictedPriceArray,
                                                "previousQty": previousQtyArray,
                                                "previousPrice": previousPriceArray,
                                                "ROI": roiArray,
                                                "SalesGrowth": salesGrowthArray,
                                                "Investment": investmentArray,
                                                "Uplift": upliftArray
                                            };

                                            // Push the JSON object into the jsonArray
                                            jsonArray.push(jsonObject);

                                        } else {
                                            break;
                                        }
                                    }
                                    console.log("Discount: " + JSON.stringify(jsonArray));
                                    roiCalculationsResult.current = jsonArray;
                                    setShowAutomateLoader(false);
                                    setShowAutomateResults(true);
                                }
                                saveActivity("run optimize")
                            }).catch(function (error) {
                                console.log(error);
                                setShowAutomateLoader(false);
                                alert(error);
                            })

                        }


                    }
                }).catch(function (error) {
                    console.log(error);
                    setShowAutomateLoader(false);
                    alert(error);
                })

            }
            else if (selectedConstraint === "Max Budget") {
                /**
                 const requestData = {
                modelId: 9,
                fmcgId: 34,
                date: date,
                product: product,
                category: category,
                subcategory: subcategory,
                size: size,
                targetPrice: 0,
                effectivePrice: 0,
                discount: 50,
                period: 3,
                Jik_Bleach_Regular_750ml_price: 430,
                Jik_Bleach_Spring_Fresh_750ml_price: 430,
                Jik_Bleach_Lemon_750ml_price: 430,
                requestType: "optimize",
                constraint: constraint.replaceAll(" ", ""),
                budget: maxconstraint
            }
                 **/
               /**
                const requestData = {
                    modelId: selecteModelId,
                    fmcgId: fmcgid,
                    retailerId: retailer,
                    date: date,
                    product: product,
                    category: category,
                    subcategory: subcategory,
                    size: size,
                    targetPrice: 0,
                    effectivePrice: 0,
                    discount: 50,
                    period: 3,
                    Jik_Bleach_Regular_750ml_price: getFilteredPrice("Jik Bleach Regular 750ml", retailer),
                    Jik_Bleach_Spring_Fresh_750ml_price: getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer),
                    Jik_Bleach_Lemon_750ml_price: getFilteredPrice("Jik Bleach Lemon 750ml", retailer),
                    requestType: "optimize",
                    constraint: "MaxDiscount",
                    budget: maxconstraint,
                    brand: brand,
                    Omo_Bleach_Lemon_700ml_price: getFilteredPrice("Omo Bleach Lemon Fresh 700ml", retailer),
                    Omo_Bleach_Regular_700ml_price: getFilteredPrice("Omo Bleach Regular 700ml", retailer),
                    Safisha_Bleach_Lemon_750ml_price: getFilteredPrice("Safisha Bleach Lemon 750ml", retailer),
                    Safisha_Bleach_Regular_750ml_price: getFilteredPrice("Safisha Bleach Regular 750ml", retailer),
                    Topex_Bleach_Lemon_750ml_price: getFilteredPrice("Topex Bleach Lemon 750ml", retailer),
                    Topex_Bleach_Regular_750ml_price: getFilteredPrice("Topex Bleach Regular 750ml", retailer),
                    Msafi_Bleach_Lavender_750ml_price: getFilteredPrice("Msafi Bleach Lavender 750ml", retailer),
                    Msafi_Bleach_Lemon_Fresh_750ml_price: getFilteredPrice("Msafi Bleach Lemon Fresh 750ml", retailer),
                    Msafi_Bleach_Potpourri_750ml_price: getFilteredPrice("Msafi Bleach Potpourri 750ml", retailer),
                    Msafi_Bleach_Regular_750ml_price: getFilteredPrice("Msafi Bleach Regular 750ml", retailer),
                    selectedRetailers: selectedRetailers.current
                  //  [
                    // {
                      //  retailerId: 3,
                       // retailerName: "Naivas"
                    //},
                    // {
                        //retailerId: 30,
                        //retailerName: "Carrefour"
                   // }
                   //  ]

               // }

                setShowAutomateLoader(true);
                axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {
                   // console.log(response);

                    // setShowLoginLoader(false);

                    const data = response.data;

                    if (data === null || data === "") {
                        console.log("Data is null")
                        alert("Data is null");
                    } else {

                        console.log(data.volumeResultList);

                        if(data.volumeResultList.length ===2){

                            maxDiscount_1_retailer.current = data.volumeResultList[0].retailerName;
                            maxDiscount_1.current = data.volumeResultList[0].volumeResponse;
                            maxDiscount_2.current = data.volumeResultList[1].volumeResponse;
                            maxDiscount_2_retailer.current = data.volumeResultList[1].retailerName;
                            //console.log(maxDiscount_1_retailer.current);

                        }
                        let volumeListLength = data.volumeResultList.length;

                        let retailerNameResult;
                        for (let retailerIndex = 0; retailerIndex < data.volumeResultList.length; retailerIndex++) {
                            retailerNameResult = data.volumeResultList[retailerIndex].retailerName;
                            maxDiscount.current = data.volumeResultList[retailerIndex].volumeResponse;

                            let gotRetailerId = retailerNameResult === "Naivas"?3:30;
                            let gotModelId = gotRetailerId === 3?15:17
                          // console.log(retailerNameResult);


                            axios.get(`https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}`).then
                            (function (response) {
                               // console.log(response);

                                // setShowLoginLoader(false);

                                const data = response.data;

                                if (data === null || data === "") {
                                    console.log("Data is null")

                                    alert("Data is null");

                                } else {
                                    console.log(data);
                                    averagePriceAndQuantity.current = data;

                                    let budget = maxconstraint;

                                    // Initialize an empty array to store JSON objects
                                    var jsonArray = [];
                                    let isFinished = false;
                                    outerLoop:
                                        for (let i = 0; i < maxDiscount.current.length; i++) {
                                            if (!isFinished) {
                                                var roiArray = [];
                                                var salesGrowthArray = [];
                                                var investmentArray = [];
                                                var predictedQtyArray = [];
                                                var predictedPriceArray = [];
                                                var previousQtyArray = [];
                                                var previousPriceArray = [];
                                                var upliftArray = [];
                                                innerLoop:
                                                    for (let j = 0; j < averagePriceAndQuantity.current.length; j++) {
                                                       // console.log("Discount Index: " + i + " product Index: " + j);
                                                        let predictedQty = maxDiscount.current[i].output[0][j]; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? maxDiscount.current[i].output[0][j] : 0;
                                                        let predictedPrice = 430 * (1 - i / 100);

                                                        let previousQty = averagePriceAndQuantity.current[j].averageSalesQuantity; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averageSalesQuantity;
                                                        let previousPrice = averagePriceAndQuantity.current[j].averagePrice; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averagePrice : 0;

                                                        let ROI = parseFloat(calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice));
                                                        let salesGrowth = (predictedQty * predictedPrice) - (previousQty * previousPrice);
                                                        let investment = -(predictedQty * (predictedPrice - previousPrice));
                                                        let uplift = calculateUplift(predictedQty, previousQty);

                                                        if (investment <= budget) {
                                                            roiArray.push(ROI);
                                                            predictedQtyArray.push(predictedQty);
                                                            predictedPriceArray.push(predictedPrice);
                                                            previousQtyArray.push(previousQty);
                                                            previousPriceArray.push(previousPrice);
                                                            salesGrowthArray.push(salesGrowth);
                                                            investmentArray.push(investment);
                                                            upliftArray.push(uplift);
                                                        } else {
                                                            isFinished = true;
                                                            break outerLoop;
                                                        }
                                                    }

                                                // Create a JSON object for each data entry
                                                let retailerName = gotRetailerId ===3?"Naivas":"Carrefour";
                                                var jsonObject = {
                                                     "Retailer":retailerName,
                                                    "Discount": i,
                                                    "predictedQty": predictedQtyArray,
                                                    "predictedPrice": predictedPriceArray,
                                                    "previousQty": previousQtyArray,
                                                    "previousPrice": previousPriceArray,
                                                    "ROI": roiArray,
                                                    "SalesGrowth": salesGrowthArray,
                                                    "Investment": investmentArray,
                                                    "Uplift": upliftArray
                                                };

                                                // Push the JSON object into the jsonArray
                                                jsonArray.push(jsonObject);

                                               // alert("pushed");
                                            } else {
                                                break outerLoop;
                                            }
                                        }
                                    // console.log("Maximize ROI: " + JSON.stringify(jsonArray));
                                  //  console.log("Budget: " + JSON.stringify(jsonArray));
                                    if(retailerIndex === 0) {
                                        roiCalculationsResult.current = jsonArray;


                                        console.log("index 0 "+JSON.stringify(jsonArray));

                                    }else if(retailerIndex === 1){

                                        roiCalculationsResult2.current = jsonArray;
                                        console.log("index 1 "+JSON.stringify(jsonArray));

                                    }

                                   // setShowAutomateLoader(false);
                                   // setShowAutomateResults(true);
                                   if(volumeListLength === 2) {
                                       if (retailerIndex === 1) {
                                           setShowAutomateLoader(false);
                                           setShowAutomateResults(true);
                                       }
                                   }else{
                                       setShowAutomateLoader(false);
                                       setShowAutomateResults(true);
                                   }

                                }
                               // saveActivity("run optimize")
                            }).catch(function (error) {
                                console.log(error);
                                setShowAutomateLoader(false);
                                alert(error);
                            })

                           // alert(retailerIndex + " "+retailerNameResult);

                            console.log(retailerNameResult);



                        }


                    }
                }).catch(function (error) {
                    console.log(error);
                    setShowAutomateLoader(false);
                    alert(error);
                })
            } else {
                alert("No constraint selected");
            }
            **/
/**
               const requestData = {
                   modelId: selecteModelId,
                   fmcgId: fmcgid,
                   retailerId: retailer,
                   date: date,
                   product: product,
                   category: category,
                   subcategory: subcategory,
                   size: size,
                   targetPrice: 0,
                   effectivePrice: 0,
                   discount: 50,
                   period: 3,
                   Jik_Bleach_Regular_750ml_price: getFilteredPrice("Jik Bleach Regular 750ml", retailer),
                   Jik_Bleach_Spring_Fresh_750ml_price: getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer),
                   Jik_Bleach_Lemon_750ml_price: getFilteredPrice("Jik Bleach Lemon 750ml", retailer),
                   requestType: "optimize",
                   constraint: "MaxDiscount",
                   budget: maxconstraint,
                   brand: brand,
                   Omo_Bleach_Lemon_700ml_price: getFilteredPrice("Omo Bleach Lemon Fresh 700ml", retailer),
                   Omo_Bleach_Regular_700ml_price: getFilteredPrice("Omo Bleach Regular 700ml", retailer),
                   Safisha_Bleach_Lemon_750ml_price: getFilteredPrice("Safisha Bleach Lemon 750ml", retailer),
                   Safisha_Bleach_Regular_750ml_price: getFilteredPrice("Safisha Bleach Regular 750ml", retailer),
                   Topex_Bleach_Lemon_750ml_price: getFilteredPrice("Topex Bleach Lemon 750ml", retailer),
                   Topex_Bleach_Regular_750ml_price: getFilteredPrice("Topex Bleach Regular 750ml", retailer),
                   Msafi_Bleach_Lavender_750ml_price: getFilteredPrice("Msafi Bleach Lavender 750ml", retailer),
                   Msafi_Bleach_Lemon_Fresh_750ml_price: getFilteredPrice("Msafi Bleach Lemon Fresh 750ml", retailer),
                   Msafi_Bleach_Potpourri_750ml_price: getFilteredPrice("Msafi Bleach Potpourri 750ml", retailer),
                   Msafi_Bleach_Regular_750ml_price: getFilteredPrice("Msafi Bleach Regular 750ml", retailer),
                   selectedRetailers: selectedRetailers.current
               };

                setShowAutomateLoader(true);

                axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {
                    const data = response.data;

                    if (data === null || data === "") {
                        console.log("Data is null");
                        alert("Data is null");
                    } else {
                        console.log(data.volumeResultList);

                        if (data.volumeResultList.length === 2) {
                            maxDiscount_1_retailer.current = data.volumeResultList[0].retailerName;
                            maxDiscount_1.current = data.volumeResultList[0].volumeResponse;
                            maxDiscount_2.current = data.volumeResultList[1].volumeResponse;
                            maxDiscount_2_retailer.current = data.volumeResultList[1].retailerName;
                        }

                        let volumeListLength = data.volumeResultList.length;

                        const processRetailer = (retailerIndex) => {
                            let retailerNameResult = data.volumeResultList[retailerIndex].retailerName;
                            maxDiscount.current = data.volumeResultList[retailerIndex].volumeResponse;

                            let gotRetailerId = retailerNameResult === "Naivas" ? 3 : 30;
                            let gotModelId = gotRetailerId === 3 ? 15 : 17;

                            axios.get(`https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}`).then(function (response) {
                                const averageData = response.data;

                                if (averageData === null || averageData === "") {
                                    console.log("Data is null");
                                    alert("Data is null");
                                } else {
                                    console.log(averageData);
                                    let budget = maxconstraint;

                                    let jsonArray = [];
                                    let isFinished = false;

                                    outerLoop:
                                        for (let i = 0; i < maxDiscount.current.length; i++) {
                                            if (!isFinished) {
                                                let roiArray = [];
                                                let salesGrowthArray = [];
                                                let investmentArray = [];
                                                let predictedQtyArray = [];
                                                let predictedPriceArray = [];
                                                let previousQtyArray = [];
                                                let previousPriceArray = [];
                                                let upliftArray = [];

                                                innerLoop:
                                                    for (let j = 0; j < averageData.length; j++) {
                                                        let predictedQty = maxDiscount.current[i].output[0][j];
                                                        let predictedPrice = 430 * (1 - i / 100);

                                                        let previousQty = averageData[j].averageSalesQuantity;
                                                        let previousPrice = averageData[j].averagePrice;

                                                        let ROI = parseFloat(calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice));
                                                        let salesGrowth = (predictedQty * predictedPrice) - (previousQty * previousPrice);
                                                        let investment = -(predictedQty * (predictedPrice - previousPrice));
                                                        let uplift = calculateUplift(predictedQty, previousQty);

                                                        if (investment <= budget) {
                                                            roiArray.push(ROI);
                                                            predictedQtyArray.push(predictedQty);
                                                            predictedPriceArray.push(predictedPrice);
                                                            previousQtyArray.push(previousQty);
                                                            previousPriceArray.push(previousPrice);
                                                            salesGrowthArray.push(salesGrowth);
                                                            investmentArray.push(investment);
                                                            upliftArray.push(uplift);
                                                        } else {
                                                            isFinished = true;
                                                            break outerLoop;
                                                        }
                                                    }

                                                let retailerName = gotRetailerId === 3 ? "Naivas" : "Carrefour";
                                                let jsonObject = {
                                                    "Retailer": retailerName,
                                                    "Discount": i,
                                                    "predictedQty": predictedQtyArray,
                                                    "predictedPrice": predictedPriceArray,
                                                    "previousQty": previousQtyArray,
                                                    "previousPrice": previousPriceArray,
                                                    "ROI": roiArray,
                                                    "SalesGrowth": salesGrowthArray,
                                                    "Investment": investmentArray,
                                                    "Uplift": upliftArray
                                                };

                                                jsonArray.push(jsonObject);
                                            } else {
                                                break outerLoop;
                                            }
                                        }

                                    if (retailerIndex === 0) {
                                        roiCalculationsResult.current = jsonArray;
                                        console.log("index 0 " + JSON.stringify(jsonArray));
                                    } else if (retailerIndex === 1) {
                                        roiCalculationsResult2.current = jsonArray;
                                        console.log("index 1 " + JSON.stringify(jsonArray));
                                    }

                                    if (volumeListLength === 2) {
                                        if (retailerIndex === 1) {
                                            setShowAutomateLoader(false);
                                            setShowAutomateResults(true);
                                        }
                                    } else {
                                        setShowAutomateLoader(false);
                                        setShowAutomateResults(true);
                                    }
                                }
                            }).catch(function (error) {
                                console.log(error);
                                setShowAutomateLoader(false);
                                alert(error);
                            });
                        };

                        for (let retailerIndex = 0; retailerIndex < data.volumeResultList.length; retailerIndex++) {
                            processRetailer(retailerIndex);
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                    setShowAutomateLoader(false);
                    alert(error);
                });

                **/

const optimizeProducts = [
    { productName: 'Harpic Power Plus Citrus 500ml', price: getFilteredPrice('Harpic Power Plus Citrus 500ml', retailer, '7171100950021') },
    { productName: 'Harpic Power Plus Lavender 500ml', price: getFilteredPrice('Harpic Power Plus Lavender 500ml', retailer, '6161100953055') },
    { productName: 'Harpic Power Plus Original 500ml', price: getFilteredPrice('Harpic Power Plus Original 500ml', retailer, '6161100952430') },
    { productName: 'Harpic Toilet Cleaner Mountain Pine 500ml', price: getFilteredPrice('Harpic Toilet Cleaner Mountain Pine 500ml', retailer, '6161100956827') },
    { productName: 'Harpic Toilet Cleaner P/P Flo 500ml', price: getFilteredPrice('Harpic Toilet Cleaner P/P Flo 500ml', retailer, '6161100956964') },
    { productName: 'Harpic Toilet Cleaner P/P Lem 500ml', price: getFilteredPrice('Harpic Toilet Cleaner P/P Lem 500ml', retailer, '6161100956988') },
    { productName: 'Hurricane 500ml', price: getFilteredPrice('Hurricane 500ml', retailer,'6164000015144') },
    { productName: 'Ace 500ml', price: getFilteredPrice('Ace 500ml', retailer, '6161100047891') },
    { productName: 'Atilla 500ml', price: getFilteredPrice('Atilla 500ml', retailer,'NAI-13002071') },
    { productName: 'Tropikal 500ml', price: getFilteredPrice('Tropikal 500ml', retailer, '6161101010511') },
    { productName: 'Safisha 500ml', price: getFilteredPrice('Safisha 500ml', retailer, '6161109448507') },
    { productName: 'Domestos 500ml', price: getFilteredPrice('Domestos 500ml', retailer, '8934868135746') },
    { productName: 'Velvex 500ml', price: getFilteredPrice('Velvex 500ml', retailer,'6161100761520') },
    { productName: 'Harpic Power Plus Original 2x500ml', price: getFilteredPrice('Harpic Power Plus Original 2x500ml', retailer, '6161100954199')},

];

if (category === "Diapers")
{
    setFilteredDiaperProducts(getDiapersOptimizeProducts());
}

const requestData = {
    modelId: selecteModelId,
    fmcgId: fmcgid,
    retailerId: retailer,
    date: date,
    product: product,
    category: category,
    subcategory: subcategory,
    size: size,
    targetPrice: currentPriceOriginal,
    effectivePrice: currentPriceOriginal,
    discount: (selectedRetailers.current.length > 1) ? 40 : 50,
    period: 3,
    currentPrice:currentPriceOriginal,
    userId:userId,
    Jik_Bleach_Regular_750ml_price: getFilteredPrice("Jik Bleach Regular 750ml", retailer),
    Jik_Bleach_Spring_Fresh_750ml_price: getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer),
    Jik_Bleach_Lemon_750ml_price: getFilteredPrice("Jik Bleach Lemon 750ml", retailer),
    requestType: "optimize",
    constraint: "MaxDiscount",
    budget: maxconstraint,
    brand: brand,
    Omo_Bleach_Lemon_700ml_price: getFilteredPrice("Omo Bleach Lemon Fresh 700ml", retailer),
    Omo_Bleach_Regular_700ml_price: getFilteredPrice("Omo Bleach Regular 700ml", retailer),
    Safisha_Bleach_Lemon_750ml_price: getFilteredPrice("Safisha Bleach Lemon 750ml", retailer),
    Safisha_Bleach_Regular_750ml_price: getFilteredPrice("Safisha Bleach Regular 750ml", retailer),
    Topex_Bleach_Lemon_750ml_price: getFilteredPrice("Topex Bleach Lemon 750ml", retailer),
    Topex_Bleach_Regular_750ml_price: getFilteredPrice("Topex Bleach Regular 750ml", retailer),
    Msafi_Bleach_Lavender_750ml_price: getFilteredPrice("Msafi Bleach Lavender 750ml", retailer),
    Msafi_Bleach_Lemon_Fresh_750ml_price: getFilteredPrice("Msafi Bleach Lemon Fresh 750ml", retailer),
    Msafi_Bleach_Potpourri_750ml_price: getFilteredPrice("Msafi Bleach Potpourri 750ml", retailer),
    Msafi_Bleach_Regular_750ml_price: getFilteredPrice("Msafi Bleach Regular 750ml", retailer),
    selectedRetailers: selectedRetailers.current,
    optimizeProducts: (category === "Diapers") ? getDiapersOptimizeProducts() : optimizeProducts,
    JikBleachRegular2plus1PromoPack3x750mlPrice: getFilteredPrice("Jik Bleach Regular 2+1 Promo Pack 3x750ml", retailer),
    segment: segment
};

                setShowAutomateLoader(true);

                axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(async function (response) {
                    const data = response.data;

                    if (data === null || data === "") {
                        console.log("Data is null");
                        alert("Data is null");
                    } else {
                        console.log(data.volumeResultList);

                        if (data.volumeResultList.length === 2) {
                            maxDiscount_1_retailer.current = data.volumeResultList[0].retailerName;
                            maxDiscount_1.current = data.volumeResultList[0].volumeResponse;
                            maxDiscount_2.current = data.volumeResultList[1].volumeResponse;
                            maxDiscount_2_retailer.current = data.volumeResultList[1].retailerName;
                        }else if(data.volumeResultList.length === 1){
                            maxDiscount.current = data.volumeResultList[0].volumeResponse;
                        }

                        let volumeListLength = data.volumeResultList.length;

                        for (let retailerIndex = 0; retailerIndex < data.volumeResultList.length; retailerIndex++) {
                            await processRetailer(data.volumeResultList[retailerIndex], retailerIndex, volumeListLength);
                        }

                        setShowAutomateLoader(false);
                        setShowAutomateResults(true);
                    }
                }).catch(function (error) {
                    console.log(error);
                    setShowAutomateLoader(false);
                    alert(error);
                });
/**
                async function processRetailer(retailerData, retailerIndex, volumeListLength) {
                    let retailerNameResult = retailerData.retailerName;
                    let maxDiscount = retailerData.volumeResponse;

                    let gotRetailerId = retailerNameResult === "Naivas" ? 3 : 30;
                    let gotModelId;
                    if(category === 'Bleach') {
                        gotModelId = gotRetailerId === 3 ? 15 : 17;
                    }else{
                        gotModelId = gotRetailerId === 3 ? 18 : 19;
                    }

                    try {
                        const response = await axios.get(`https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}&category=${category}`);
                        const averageData = response.data;

                        if (averageData === null || averageData === "") {
                            console.log("Data is null");
                            alert("Data is null");
                        } else {
                            console.log(averageData);
                            let budget = maxconstraint;

                            let jsonArray = [];
                            let isFinished = false;

                            outerLoop:
                                for (let i = 0; i < maxDiscount.length; i++) {
                                    if (!isFinished) {
                                        let roiArray = [];
                                        let salesGrowthArray = [];
                                        let investmentArray = [];
                                        let predictedQtyArray = [];
                                        let predictedPriceArray = [];
                                        let previousQtyArray = [];
                                        let previousPriceArray = [];
                                        let upliftArray = [];

                                        innerLoop:
                                            for (let j = 0; j < averageData.length; j++) {
                                                let predictedQty = maxDiscount[i].output[0][j];
                                                let predictedPrice;
                                                let tempCurrentPrice;
                                                if(category === 'Bleach') {
                                                    predictedPrice = 430 * (1 - i / 100);
                                                    tempCurrentPrice = 430;
                                                }else{
                                                    predictedPrice = 600 * (1 - i / 100);
                                                    tempCurrentPrice = 600;
                                                }
                                                let lastthreemonthsales =  averageData[j].averageSalesValue;
                                                let previousQty = averageData[j].previousQuantity;
                                                let previousPrice = averageData[j].averagePrice;
                                                let ROI = calculateSalesROiOptimize(predictedQty,predictedPrice,previousQty,tempCurrentPrice,lastthreemonthsales);
                                                //let ROI = parseFloat(calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice));
                                                let salesGrowth = ((predictedQty * predictedPrice) - lastthreemonthsales);
                                                let investment = ((tempCurrentPrice  - predictedPrice)*predictedQty);
                                               // let uplift = calculateUplift(predictedQty, previousQty); //calculateValueUplift(predictedPrice, previousQty);
                                                let uplift = calculateValueUplift((predictedPrice*predictedQty), lastthreemonthsales);

                                                if (investment <= budget) {
                                                    roiArray.push(ROI);
                                                    predictedQtyArray.push(predictedQty);
                                                    predictedPriceArray.push(predictedPrice);
                                                    previousQtyArray.push(previousQty);
                                                    previousPriceArray.push(previousPrice);
                                                    salesGrowthArray.push(salesGrowth);
                                                    investmentArray.push(investment);
                                                    upliftArray.push(uplift);
                                                } else {
                                                    isFinished = true;
                                                    break outerLoop;
                                                }
                                            }

                                        let retailerName = gotRetailerId === 3 ? "Naivas" : "Carrefour";
                                        let jsonObject = {
                                            "Retailer": retailerName,
                                            "Discount": i,
                                            "predictedQty": predictedQtyArray,
                                            "predictedPrice": predictedPriceArray,
                                            "previousQty": previousQtyArray,
                                            "previousPrice": previousPriceArray,
                                            "ROI": roiArray,
                                            "SalesGrowth": salesGrowthArray,
                                            "Investment": investmentArray,
                                            "Uplift": upliftArray
                                        };

                                        jsonArray.push(jsonObject);
                                    } else {
                                        break outerLoop;
                                    }
                                }

                            if (retailerIndex === 0) {
                                roiCalculationsResult.current = jsonArray;
                                console.log("index 0 " + JSON.stringify(jsonArray));
                            } else if (retailerIndex === 1) {
                                roiCalculationsResult2.current = jsonArray;
                                console.log("index 1 " + JSON.stringify(jsonArray));
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        setShowAutomateLoader(false);
                        alert(error);
                    }
                }
**/

async function processRetailer(retailerData, retailerIndex, volumeListLength) {
    let retailerNameResult = retailerData.retailerName;
    let maxDiscount = retailerData.volumeResponse;

    // Determine retailer and model IDs based on the category and retailer name
    let gotRetailerId = retailerNameResult === "Naivas" ? NAIVAS_RETAILER_ID : CARREFOUR_RETAILER_ID;
    let gotModelId = (category === 'Bleach')
        ? (gotRetailerId === NAIVAS_RETAILER_ID ? NAIVAS_BLEACHES_MODEL_ID : CARREFOUR_BLEACHES_MODEL_ID)
        : (gotRetailerId === NAIVAS_RETAILER_ID ? NAIVAS_TOILETCLEANERS_MODEL_ID : CARREFOUR_TOILETCLEANERS_MODEL_ID);

    if(category === "Diapers")
    {
        gotModelId = gotRetailerId === NAIVAS_RETAILER_ID ? DIAPERS_MODEL_ID : DIAPERS_MODEL_ID;
    }

    console.log('Retailer ID:', gotRetailerId);
    console.log('Model ID:', gotModelId);

    try {
        let averagePriceAndQuantityUrl;

        if(category === "Diapers")
        {
            averagePriceAndQuantityUrl = `https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}&category=${category}&subcategory=${subcategory}&date=${date}&segment=${segment}&size=${size}`;
        }
        else
        {
            averagePriceAndQuantityUrl = `https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity?modelid=${gotModelId}&retailerid=${gotRetailerId}&category=${category}&subcategory=${subcategory}&date=${date}&size=${size}`;
        }

        const response = await axios.get(averagePriceAndQuantityUrl);
        const averageData = response.data;

        if (!averageData) {
            console.log("Data is null");
            alert("Data is null");
            return;
        }


        console.log('Average Data:', averageData);

        let budget = maxconstraint;
        let jsonArray = [];
        let isFinished = false;

        //outerLoop:
            for (let i = 0; i < maxDiscount.length; i++) {
               // if (isFinished) break;

                let roiArray = [];
                let salesGrowthArray = [];
                let investmentArray = [];
                let predictedQtyArray = [];
                let predictedPriceArray = [];
                let previousQtyArray = [];
                let previousPriceArray = [];
                let upliftArray = [];

                for (let j = 0; j < averageData.length; j++) {
                    let predictedQty = maxDiscount[i].output[0][j];
                    let predictedPrice;
                    let tempCurrentPrice;

                    if (category === 'Bleach') {
                        predictedPrice = 430 * (1 - i / 100);
                        tempCurrentPrice = 430;
                    }
                    else if (category === 'Diapers')
                    {
                        predictedPrice = 1019 * (1 - i / 100);
                        tempCurrentPrice = 1019;    
                    } 
                    else {
                        predictedPrice = 600 * (1 - i / 100);
                        tempCurrentPrice = 600;
                    }

                    let lastThreeMonthSales = averageData[j].averageSalesValue;
                    let previousQty = averageData[j].previousQuantity;
                    let previousPrice = averageData[j].averagePrice;

                    let ROI = calculateSalesROiOptimize(predictedQty, predictedPrice, previousQty, tempCurrentPrice, lastThreeMonthSales);
                    let salesGrowth = ((predictedQty * predictedPrice) - lastThreeMonthSales);
                    let investment = ((tempCurrentPrice - predictedPrice) * predictedQty);
                    let uplift = calculateValueUplift((predictedPrice * predictedQty), lastThreeMonthSales);

                    /*if (investment <= budget) {
                        console.log(`Volume Output: ${predictedQty}, Investment: ${investment}`);
                        roiArray.push(ROI);
                        predictedQtyArray.push(predictedQty);
                        predictedPriceArray.push(predictedPrice);
                        previousQtyArray.push(previousQty);
                        previousPriceArray.push(previousPrice);
                        salesGrowthArray.push(salesGrowth);
                        investmentArray.push(investment);
                        upliftArray.push(uplift);
                    } else {
                        console.log("investment value is greater than or equal to budget, breaking the loop                                                                   "+investment);
                        //isFinished = true;
                       // break outerLoop;
                    }*/

                       console.log(`Volume Output: ${predictedQty}, Investment: ${investment}`);
                        roiArray.push(ROI);
                        predictedQtyArray.push(predictedQty);
                        predictedPriceArray.push(predictedPrice);
                        previousQtyArray.push(previousQty);
                        previousPriceArray.push(previousPrice);
                        salesGrowthArray.push(salesGrowth);
                        investmentArray.push(investment);
                        upliftArray.push(uplift);
                }

                let retailerName = gotRetailerId === NAIVAS_RETAILER_ID ? "Naivas" : "Carrefour";
                let jsonObject = {
                    "Retailer": retailerName,
                    "Discount": i,
                    "predictedQty": predictedQtyArray,
                    "predictedPrice": predictedPriceArray,
                    "previousQty": previousQtyArray,
                    "previousPrice": previousPriceArray,
                    "ROI": roiArray,
                    "SalesGrowth": salesGrowthArray,
                    "Investment": investmentArray,
                    "Uplift": upliftArray
                };

                jsonArray.push(jsonObject);
            }

        if (retailerIndex === 0) {
            roiCalculationsResult.current = jsonArray;
            console.log("index 0 result:", JSON.stringify(jsonArray));
        } else if (retailerIndex === 1) {
            roiCalculationsResult2.current = jsonArray;
            console.log("index 1 result:", JSON.stringify(jsonArray));
        }

    } catch (error) {
        console.log(error);
        setShowAutomateLoader(false);
        alert(error);
    }
}


            }



        }
    };

    const handleClearEventButtonClicked = ()=>{

        setLineChartXAxis([]);
        setLineChartYAxis([]);

        saveActivity("clear chart");
    }

    const handleLockPredictionEvent = ()=>{

     let   requestData = {
            modelId: selecteModelId,
            fmcgId: fmcgid,
            retailerId:retailer,
            date: date,
            product: product,
            category: category,
            subcategory: subcategory,
            size: size,
            currentPrice:currentPriceOriginal,
            userId:userId,
            targetPrice: proposedPrice,
            effectivePrice: 0,
            discount: 0,
            period: period,
            Jik_Bleach_Regular_750ml_price: 0,
            Jik_Bleach_Spring_Fresh_750ml_price: 0,
            Jik_Bleach_Lemon_750ml_price: 0,
            requestType:"lockprediction"


    }

    axios.post("https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions",requestData).then(function (response) {
        let data = response.data;

        let statusCode = data.statusCode;

        let message = data.message;

        if(statusCode ==="200"){
         setDialogTitle("Prediction Locking");
         setDialogText("prediction has now been locked");
         setAlertDialogStatus(true);

            checkLockedPredictionAvailability(date,product,category,subcategory,retailer,selecteModelId);


        }else{
            setDialogTitle("Error");
            setDialogText(message);
            setAlertDialogStatus(true);


        }

        console.log(`${statusCode}: ${message}`);

        saveActivity("lock prediction")
    }).catch(function (error) {
        console.log(error);
        alert(error)
    });








}

const checkLockedPredictionAvailability =(predictionDate,predictionProduct,predictionCategory,predictionSubCategory,predictionRetailer,predictionModel)=>{

if (product === "select") {
        console.log('please select a product');


    } else if (predictionDate === "0") {

    console.log('please select a date');

} else if(retailerName.length === 0) {

    console.log('please select a retailer');
}else{

    let discountPercentage = (discount / 100)
    let calculatedEffectivePrice = calculateEffectivePrice(currentPriceOriginal, discount, period);

    let  requestData = {
        modelId: predictionModel,
        fmcgId: fmcgid,
        retailerId:predictionRetailer,
        date: predictionDate,
        product: predictionProduct,
        category: predictionCategory,
        subcategory: predictionSubCategory,
        size: size,
        targetPrice: proposedPrice,
        effectivePrice: calculatedEffectivePrice,
        discount: discountPercentage,
        period: period,
        Jik_Bleach_Regular_750ml_price: 0,
        Jik_Bleach_Spring_Fresh_750ml_price: 0,
        Jik_Bleach_Lemon_750ml_price: 0,
        currentPrice:currentPriceOriginal,
        userId:userId,
        requestType:CHECK_LOCKED_PREDICTION_REQUESTTYPE
    }

    axios.post(MAIN_URL,requestData).then((response)=>{

        console.log(response);

        const data = response.data;

        //got current prices successfully
        if (data.statusCode === "200") {

            if(data.lockedPredictionResponse.length >0){

                setLockedPredictionValues(data.lockedPredictionResponse);
                setShowLockedPredictionButton(true);

            }else{
                setLockedPredictionValues([]);
                setShowLockedPredictionButton(false);
                console.log(data.message);
            }

        }else{
            setLockedPredictionValues([]);
            setShowLockedPredictionButton(false);
            console.log(data.message);
        }


        }).catch((error)=>{
        console.log(error);
    })

}

}

const loadLockedPrediction  =()=>{
   if(lockedPredictionValues.length>0){

       let scenarioId   =   lockedPredictionValues[0].scenario_id;
       let modelId   =   lockedPredictionValues[0].model_id;
       let gotCurrentPrice   =   lockedPredictionValues[0].current_price;
       let gotPredictedPrice   =   lockedPredictionValues[0].predicted_price;
       let got_retailerId   =   lockedPredictionValues[0].retailer_id;
       let got_discount   =   lockedPredictionValues[0].discount;
       let got_period   =   parseInt(lockedPredictionValues[0].offer_period.replace("wk",""));

       setLockedEffectivePrice(gotPredictedPrice);
       setLockedDiscount((got_discount*100));
       setLockedPeriod(got_period);
     let  requestData = {
           modelId: modelId,
           fmcgId: fmcgid,
           retailerId:got_retailerId,
           date: date,
           product: product,
           category: category,
           subcategory: subcategory,
           size: size,
           targetPrice: gotPredictedPrice,
           effectivePrice: gotPredictedPrice,
           discount: got_discount,
           period: got_period,
           Jik_Bleach_Regular_750ml_price: 0,
           Jik_Bleach_Spring_Fresh_750ml_price: 0,
           Jik_Bleach_Lemon_750ml_price: 0,
           currentPrice:gotCurrentPrice,
           userId:userId,
         scenarioId:scenarioId,
           requestType:LOAD_LOCKED_PREDICTION_REQUESTTYPE
       }

       setLoading(true);
       requestModelResults(requestData);

   }else{
       console.log("locked  values  not found");
   }

}

const handlePredictButtonClicked = () => {


       // alert(selecteModelId + " "+retailerName + " "+ retailer);


        if (currentPricesList.length === 0) {
            alert('current prices havent loaded');

        } else if (product === "select") {
            alert('please select a product');

        } else if (currentPrice === 0) {
            alert('current price has not been set');

        } else if (date === "0") {
            alert('please set a date');

            //}else if(discount === 0){


            //alert('please type a discount rate');

        } else if (period === 0) {

            alert('please select a discount period');

        } else if(retailerName.length === 0) {

            alert("please select a retailer");

        }else{

            //calculate average price


            //get prices for "Jik Bleach Regular 750ml", "Jik Bleach Spring Fresh 750ml", "Jik Bleach Lemon 750ml"

            /**
             let jik_bleach_regular_price = (parseFloat(getFilteredPrice("Jik Bleach Regular 750ml", retailer))*(1 - (parseFloat(discount)/100)));
             let jik_bleach_springfresh_price = (parseFloat(getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer))*(1 - (parseFloat(discount)/100)));
             let jik_bleach_lemon_price = (parseFloat(getFilteredPrice("Jik Bleach Lemon 750ml", retailer))*(1 - (parseFloat(discount)/100)));
             **/
            let requestData;
            if(parseInt(fmcgid) === RECKITT_FMCG_ID) {

                if(checkProductNameSizeMatch(product, size, category) === true) {
                    if (category.toLowerCase().includes("toilet")) {
                        let discountPercentage = parseFloat((discount / 100).toFixed(2));
                        let requestEffectivePrice;
                        let HarpicPowerPlusOriginal2x500mlPrice;
                        let HarpicPowerPlusCitrus2x500mlPrice;
                        if (product === "Harpic Power Plus Original 2x500ml" || product === "Harpic Power Plus Citrus 2x500ml") {
                            requestEffectivePrice = parseFloat(getFilteredPrice("Harpic Power Plus Original 500ml", retailer));


                            if (product === "Harpic Power Plus Original 2x500ml") {
                                HarpicPowerPlusOriginal2x500mlPrice = proposedPrice;
                                HarpicPowerPlusCitrus2x500mlPrice = parseFloat(getFilteredPrice("Harpic Power Plus Citrus 2x500ml", retailer));
                                setEffectivePrice(proposedPrice);
                            } else if (product === "Harpic Power Plus Citrus 2x500ml") {
                                HarpicPowerPlusCitrus2x500mlPrice = proposedPrice;
                                HarpicPowerPlusOriginal2x500mlPrice = parseFloat(getFilteredPrice("Harpic Power Plus Original 2x500ml", retailer));
                                setEffectivePrice(proposedPrice);
                            }

                        } else {
                            requestEffectivePrice = calculateEffectivePrice(currentPriceOriginal, discount, period);
                            HarpicPowerPlusCitrus2x500mlPrice = parseFloat(getFilteredPrice("Harpic Power Plus Citrus 2x500ml", retailer));
                            HarpicPowerPlusOriginal2x500mlPrice = parseFloat(getFilteredPrice("Harpic Power Plus Original 2x500ml", retailer));
                            setEffectivePrice(requestEffectivePrice);
                        }


                        //let calculatedEffectivePrice = calculateEffectivePrice(parseFloat(getFilteredPrice(product, retailer,barcode)), discount, period);
                        // const requestData = { modelId:4,fmcgId:34,date:"2023-08-01",product:product,category:category, subcategory:subcategory, size:size, Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price, Jik_Bleach_Spring_Fresh_750ml_price:jik_bleach_springfresh_price, Jik_Bleach_Lemon_750ml_price:jik_bleach_lemon_price}

                        requestData = {
                            modelId: selecteModelId,
                            fmcgId: fmcgid,
                            retailerId: retailer,
                            date: date,
                            product: product,
                            category: category,
                            subcategory: subcategory,
                            size: size,
                            targetPrice: proposedPrice,
                            effectivePrice: requestEffectivePrice,
                            discount: discountPercentage,
                            period: period,
                            Jik_Bleach_Regular_750ml_price: 0,
                            Jik_Bleach_Spring_Fresh_750ml_price: 0,
                            Jik_Bleach_Lemon_750ml_price: 0,
                            currentPrice: currentPriceOriginal,
                            HarpicPowerPlusOriginal2x500mlPrice: HarpicPowerPlusOriginal2x500mlPrice,
                            HarpicPowerPlusCitrus2x500mlPrice: HarpicPowerPlusCitrus2x500mlPrice,
                            userId: userId,
                            requestType: WHATIF_REQUESTTYPE
                        }


                    } else {
                        let calculatedEffectivePrice = calculateEffectivePrice(parseFloat(getFilteredPrice(product, retailer)), discount, period);
                        let jik_bleach_regular_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Regular 750ml", retailer)), discount, period);
                        let jik_bleach_springfresh_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer)), discount, period);
                        let jik_bleach_lemon_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Lemon 750ml", retailer)), discount, period);
                        let jik_bleach_regular_1_5_price = retailer === NAIVAS_RETAILER_ID ? calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Regular 1.5L", retailer)), discount, period) : calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Regular Perfumed Promo 1.5L", retailer)), discount, period);
                        let jik_bleach_regular_2_2_5_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Regular 2.25L", retailer)), discount, period);

                        let JikBleachRegular2plus1PromoPack3x750mlPrice;
                        if (product === "Jik Bleach Regular 2+1 Promo Pack 3x750ml") {
                            JikBleachRegular2plus1PromoPack3x750mlPrice = proposedPrice;
                            setEffectivePrice(proposedPrice);
                        } else {
                            JikBleachRegular2plus1PromoPack3x750mlPrice = parseFloat(getFilteredPrice("Jik Bleach Regular 2+1 Promo Pack 3x750ml", retailer));
                            setEffectivePrice(calculatedEffectivePrice);
                        }


                        //alert(jik_bleach_regular_price + " "+ jik_bleach_springfresh_price + " "+ jik_bleach_lemon_price);
                        //let discountPercentage = (discount / 100)
                        let discountPercentage = parseFloat((discount / 100).toFixed(2));
                        // const requestData = { modelId:4,fmcgId:34,date:"2023-08-01",product:product,category:category, subcategory:subcategory, size:size, Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price, Jik_Bleach_Spring_Fresh_750ml_price:jik_bleach_springfresh_price, Jik_Bleach_Lemon_750ml_price:jik_bleach_lemon_price}
                        requestData = {
                            modelId: selecteModelId,
                            fmcgId: fmcgid,
                            retailerId: retailer,
                            date: date,
                            product: product,
                            category: category,
                            subcategory: subcategory,
                            size: size,
                            currentPrice: currentPriceOriginal,
                            userId: userId,
                            targetPrice: proposedPrice,
                            effectivePrice: calculatedEffectivePrice,
                            discount: discountPercentage,
                            period: period,
                            Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price,
                            Jik_Bleach_Spring_Fresh_750ml_price: jik_bleach_springfresh_price,
                            Jik_Bleach_Lemon_750ml_price: jik_bleach_lemon_price,
                            JikBleachRegular2plus1PromoPack3x750mlPrice: JikBleachRegular2plus1PromoPack3x750mlPrice,
                            Jik_Bleach_Regular_1_5_price: jik_bleach_regular_1_5_price,
                            Jik_Bleach_Regular_2_25_price: jik_bleach_regular_2_2_5_price,
                            requestType: WHATIF_REQUESTTYPE

                        }
                    }

                    setLoading(true);
                    requestModelResults(requestData);
                }else{
                    alert("please ensure the product name belongs to the correct size");
                }
            }else {
                //Kimberly clarks
                if (!product.includes(segment)) {
                 alert(`please ensure  the product contains ${segment} segment`)

                } else if (!product.includes(size)) {
                    alert(`please ensure  the product contains ${size} size`)

                } else if (!product.includes(brand)) {

                    alert(`please ensure  the product contains ${brand} brand`)

                } else {
                    let calculatedEffectivePrice = calculateEffectivePrice(parseFloat(getFilteredPrice(product, retailer)), discount, period);
                    setEffectivePrice(calculatedEffectivePrice);
                    let huggiesPrice = calculateEffectivePrice(parseFloat(getFilteredPrice(`Huggies ${segment} ${size}`, retailer)), discount, period);


                    let discountPercentage = parseFloat((discount / 100).toFixed(2));
                    requestData = {
                        modelId: selecteModelId,
                        fmcgId: fmcgid,
                        retailerId: retailer,
                        date: date,
                        product: product,
                        category: category,
                        subcategory: subcategory,
                        size: size,
                        currentPrice: currentPriceOriginal,
                        userId: userId,
                        targetPrice: proposedPrice,
                        effectivePrice: calculatedEffectivePrice,
                        discount: discountPercentage,
                        period: period,
                        brand: brand,
                        segment: segment,
                        huggiesPrice: huggiesPrice,
                        requestType: WHATIF_REQUESTTYPE

                    }

                    setLoading(true);
                    requestModelResults(requestData);
                }
            }


        }

        //
    }

    const requestModelResults=(requestData)=>{
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {

            console.log(response);
            setLoading(false);
            const data = response.data;

            //got current prices successfully
            if (data.statusCode === "200") {
                let inference_data_results;
                if(parseInt(fmcgid) === RECKITT_FMCG_ID) {
                    inference_data_results = category.toLowerCase().includes("toilet") ? data.toiletCleanersResponseItemsList[0] : data.bleachesResponseItemsList[0];
                }else{

                    inference_data_results = data.diapersResponseItemsList[0];
                }

                let previous_quantities_results = data.previousQuantitiesList;

                let previous_quantities_brands_results = data.previousQuantitiesBrandsList

                let previous_quantities_results_original = data.previousQuantitiesList;

                // setLast3MonthsAverageSalesList(data.lastAverageSalesValuesItemsList);

                // alert(data.lastAverageSalesValuesItemsList.length);

                // setLast3MonthAverageValue();

                const previous_month_results_original = data.actualCategoryQuantitiesList

                //order based on quantity
                const previous_month_results = previous_month_results_original.sort((a, b) => {

                    return (b.quantity - a.quantity);


                });
                // alert(`${getYearFormatted(predictedDate)} ${previous_quantities_results[previous_quantities_results.length - 1].year}`)


                let actualMonthQuantity = 0;
                //check if previous quantities contain the current month
                if ((getYearFormatted(date) === previous_quantities_results[previous_quantities_results.length - 1].year) && (getMonthFormatted(date).toLowerCase() === previous_quantities_results[previous_quantities_results.length - 1].month.toLowerCase())) {

                    actualMonthQuantity = previous_quantities_results[previous_quantities_results.length - 1].qty;
                    //get predicted month year and current month year
                    // let actualMonthMonth = previous_quantities_results[previous_quantities_results.length - 1].month;
                    // let actualMonthYear = previous_quantities_results[previous_quantities_results.length - 1].year;

                    //remove extra month element
                    previous_quantities_results.pop();

                }


                //filter to show previous quantities;

                let previousQuantities = [];
                let predictedQuantities = [];
                let previousQuantitiesLabels = [];
                let previousMonthLabels = [];
                let previousMonthValues = [];
                let predictedMonthLabels = [];
                let predictedMonthValues = [];
                let predictedMonthValuesLabels = [];
                let lastPreviousQuantityMonthYear;

                previous_quantities_results.forEach(function (value) {

                    previousQuantities.push(value.qty);
                    predictedQuantities.push(parseFloat(value.qty.toFixed(0)));
                    previousQuantitiesLabels.push(`${value.month.substring(0, 3)} ${value.year}`);
                    lastPreviousQuantityMonthYear = `${value.month} ${value.year}`


                });


                let predictedValue;
                let predictionTotalSalesQty = 0;
                let predictedProducts = new Set();
                //filter to add prediction
                Object.keys(inference_data_results).forEach(key => {
                    // predictedMonthValues.push(parseFloat(inference_data_results[key]))

                    predictionTotalSalesQty += parseFloat(inference_data_results[key]);

                    //predictedMonthLabels.push(key.replace("Qty", ""));

                    if(parseInt(fmcgid) === RECKITT_FMCG_ID) {
                        predictedMonthValuesLabels.push({
                            label: key.replace("Qty", ""),
                            value: parseFloat(inference_data_results[key])
                        });

                        if(category.toLowerCase().includes("toilet")){
                            if(size === TOILETCLEANERS_500ML) {
                                if (product.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/", "").includes(key.replace("Qty", "").replace("2x500ml", "").replace("500ml","").toLowerCase())) {
                                   if(isValuePack(product)){
                                       predictedProducts.add(product);
                                       predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                                       console.log("found " + key);
                                    }else
                                    {
                                        if (!predictedProducts.has(product)) {
                                            predictedProducts.add(product);
                                            predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                                            console.log("found " + key);
                                        }
                                    }

                                }
                            }else if(size === TOILETCLEANERS_1L){
                                if (product.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/","").replace("mpine", "mountainpine").replace("tcpowerpluslemon", "toiletcleanerpplem").includes(key.replace("Qty", "").replace("2x500ml", "").replace("500ml","").toLowerCase())) {
                                    if(isValuePack(product)){
                                        predictedProducts.add(product);
                                        predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                                        console.log("found " + key);
                                    }else {
                                        if (!predictedProducts.has(product)) {
                                            predictedProducts.add(product);
                                            predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                                            console.log("found " + key);
                                        }
                                    }

                                }
                            }
                        }else if(category.toLowerCase().includes("bleach")){

                            if(size === BLEACHES_750ML){

                                //PromoPack3x750ml
                                if (product.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/","").includes(key.replace("Qty", "").replace("PromoPack3x750ml", "").replace("750ml","").replace("700ml", "").toLowerCase())) {

                                    predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                                    console.log("found " + key);

                                }
                            }else{
                                if (product.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/","").includes(key.replace("Qty", "").replace("PromoPack3x750ml", "").replace("750ml","").replace("700ml", "").toLowerCase())) {

                                    predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                                    console.log("found " + key);

                                }
                            }
                        }

                    }else{
                        //Kimberly clarks
                        let gotProduct =`${key.replace("Qty", "").charAt(0).toUpperCase() + key.replace("Qty", "").slice(1)} ${segment} ${size}`;
                        predictedMonthValuesLabels.push({
                            label:gotProduct ,
                            value: parseFloat(inference_data_results[key])
                        });

                        if (product.toLowerCase().includes(gotProduct.toLowerCase())) {

                            predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                            console.log("found " + key);

                        }

                    }

                });

                let predictedMonthValuesLabelsSorted = predictedMonthValuesLabels.sort(function (a, b) {


                    return (b.value - a.value);
                });

                predictedMonthValuesLabelsSorted.forEach((value) => {

                    if(value.value !== 0) {
                        predictedMonthLabels.push(`${value.label.charAt(0).toUpperCase()}${value.label.slice(1)}`);
                       // alert(`${value.label.charAt(0).toUpperCase()}${value.label.slice(1)}`);
                        predictedMonthValues.push(parseFloat(value.value.toFixed(0)));
                    }
                });


                let previousMonthQtyTotal = 0;

                previous_month_results.forEach(function (value) {
                    previousMonthValues.push(parseFloat(value.quantity))
                    previousMonthLabels.push(value.product)
                    previousMonthQtyTotal += parseFloat(value.quantity);


                });

                let previousMonthProduct;

                if(parseInt(fmcgid) === RECKITT_FMCG_ID) {

                     previousMonthProduct = previous_month_results.filter((value) => {

                        return value.product === product;

                    });
                }else{

                    previousMonthProduct = previous_month_results.filter((value) => {

                        return (value.product === product);

                    });


                }
                // alert(barcode);

                //add if acrual value for predicted month exists only
                if (actualMonthQuantity !== 0) {
                    previousQuantities.push(actualMonthQuantity);
                }


                previousQuantitiesLabels.push(getNextMonth(lastPreviousQuantityMonthYear));
                predictedQuantities.push(predictedValue);

                //check if its an appended actual value
                if(previous_quantities_results_original[previous_quantities_results_original.length - 1].sales_value === 0){
                    previousQuantities.pop();
                }
                if(requestData.requestType ===WHATIF_REQUESTTYPE){
                    setLineChartType(PREDICTED_LINE_CHART);
                    setDisableLockPrediction(false)
                }else if(requestData.requestType ===LOAD_LOCKED_PREDICTION_REQUESTTYPE){
                    setLineChartType(LOCKED_LINE_CHART);
                    setDisableLockPrediction(true)
                }

                setTotalPredictedSalesQty(predictionTotalSalesQty);
                setLineChartYAxis(previousQuantities);
                setLineChartYAxisPredicted(predictedQuantities);
                setLineChartXAxis(previousQuantitiesLabels);
                setTotalPreviousMonthQty(previousMonthQtyTotal);
                setPreviousMonthProductQty(previousMonthProduct[0].quantity)
                setPreviousMonthResults(previous_month_results);
                setMarketViewArrayPredictedLabels(predictedMonthLabels);
                setMarketViewArrayPredictedValues(predictedMonthValues);
                setMarketViewArrayActualLabels(previousMonthLabels);
                setMarketViewArrayActualValues(previousMonthValues);
                setPreviousPrice(previous_quantities_results[previous_quantities_results.length - 1].avg_price);
                setLast3MonthAverageValue(getLast3MonthAverageSales(data.lastAverageSalesValuesItemsList,product));
                setLast3MonthsAverageSalesList(data.lastAverageSalesValuesItemsList)
                setPredictedResults(inference_data_results);
                setPreviousValuesBrands(previous_quantities_brands_results);
                // alert("previous price" + previous_quantities_results[previous_quantities_results.length - 1].avg_price);

                resetWhatIfChart(false);

                //alert("got inference data successfully")
                saveActivity("predict")
            } else {

                setLineChartYAxis([]);
                setLineChartXAxis([]);
                console.log(data.message);

                let message = data.message;
                if(message.includes("Please lock a prediction")){

                    setDialogTitle(`Lock ${getMonthFormatted(previousDate)} Prediction First`);
                    setDialogText(message);
                    setAlertDialogStatus(true);

                }else {
                    // alert(data.message);
                    setDialogTitle("Error");
                    setDialogText(message);
                    setAlertDialogStatus(true);
                }


            }


        }).catch(function (error) {
            setLoading(false)
            console.log(error);

            saveActivity("predict error")
        });
    }
    const handleCurrentViewButtonClicked = () => {
        //alert('currentview');
        setShowDoghnutChartWhatIf(false);
        setShowStackBarWhatIf(false);
    }

    const handleMarketViewButtonClicked = () => {

        //alert('marketview');
        setShowDoghnutChartWhatIf(true);
        setShowStackBarWhatIf(false);

    }

    const handleBrandViewButtonClicked = () => {

        //alert('marketview');
        setShowStackBarWhatIf(true);
        setShowDoghnutChartWhatIf(false);


    }


    const handleDontHaveAccount = () => {

        setShowLoginScreen(false);
        setShowRegistrationScreen(true);
    }

    const handleGoBackButtonInAccountRegistration = () => {
        setShowLoginScreen(true)
        setShowRegistrationScreen(false);
        setShowRegistrationAlert(false);

    }

//  <!--<RegistrationForm appFunction={registerUser}/>-->
    let header =<Header/>;
    let sidebar  =  <Sidebar onhandleAutomateStateChange={handleAutomateStateChange}
                             onhandleShowScenarioStateChange={handleShowScenarioStateChange}
                             onhandleShowSettingsStateChange = {handleShowSettingsStateChange}
                             onhandleShowmyPlanStateChange = {handleShowMyPlanStateChange}
                             onLogoutStateChange={handleLogoutStateChange}
                             activeLink = {activeLink}
                             onChangeActiveLink = {handleSetActiveLink}/>;

    let leverComponent;
    if (showScenario) {
        leverComponent = <Scenarios sectionname="Scenarios" userid = {userId} fmcgid = {fmcgid} subcategory = {subcategory} category = {category} product = {product} size = {size} modelId = {selecteModelId} retailer = {retailer} date = {date} previousDate = {previousDate} period = {period} modelId = {selecteModelId} pricesList = {currentPricesList} discount = {discount}/>
    } else {

        if(!showAutomate) {
            leverComponent = <Levers sectionname="Levers" proposedPrice={proposedPrice} currentPrice={currentPrice} discount = {discount} absoluteDiscount = {absoluteDiscount}   showLockedPrediction ={showLockedPredictionButton}
                                     onhandlePredictButton={handlePredictButtonClicked}
                                     onHandleLoadLockedPredictionButton={loadLockedPrediction}
                                     onhandleDropDownStateChange={handleDropDownStateChange}
                                     onhandleTextBoxStateChange={handleTextBoxStateChange}
            />

        }else{
            leverComponent = <AutomateLevers sectionname="Optimization Objectives & Constraints"  onhandleDropDownStateChange={handleDropDownStateChange}  onhandleOptimizeButton={handleOptimizeButtonClicked} objective = {objective} constraints = {constraint}  onhandleTextBoxStateChange={handleTextBoxStateChange}></AutomateLevers>
        }
    }
    let showChart;
    let showButtonGroup;
    if (!showScenario) {

        //show values
        if (showAutomate) {

            if(showAutomateResults) {
                console.log("Max Discount Output: " + maxDiscount)
                // const discountValues = maxDiscount.current.map(x => x["Discount"]).join(',');
                //  const volumeValues = maxDiscount.current.map(x => x["Output"]).join(',');

                // showChart = (discountValues.length > 0) ? <p>Discount: {discountValues}</p> : <p></p>;
                // showButtonGroup = (volumeValues.length > 0) ? <p>Volume: {volumeValues}</p> : <p></p>;
//alert("ok");

                showChart = <AutomateResults product = {product}
                                             category = {category}
                                             subcategory = {subcategory}
                                             brand = {brand}
                                             size = {size}
                                             date = {date}
                                             retailer = {retailerName} retailers = {selectedRetailers.current} maxconstraint = {maxconstraint} objective = {objective} constraint = {constraint} target = {product} maxDiscount = {maxDiscount.current} maxDiscount1Retailer = {maxDiscount_1_retailer.current} maxDiscount1 = {maxDiscount_1.current} maxDiscount2 = {maxDiscount_2.current} maxDiscount2Retailer = {maxDiscount_2_retailer.current}  calculationsResultArray = {roiCalculationsResult.current} calculationsResultArray2 = {roiCalculationsResult2.current} productIndex = {productIndex}/>
                showButtonGroup = <p></p>
            }else{
                if(showAutomateLoader){
                    showChart = <CircularProgress/>;
                    showButtonGroup = <p></p>;
                }else {
                    showChart = <p></p>;
                    showButtonGroup = <p></p>;
                }
            }


        }else{

            if (!isLoading) {
                if (lineChartXAxis.length > 0 && lineChartYAxis.length > 0) {
                    showButtonGroup = <><ButtonGroup variant="contained" aria-label="Basic button group">
                        <Button onClick={handleCurrentViewButtonClicked}>Product View</Button>
                        <Button onClick={handleBrandViewButtonClicked}>Brand View</Button>
                        <Button onClick={handleMarketViewButtonClicked}>Market View</Button>


                    </ButtonGroup>
                    </>;
                    if (showDoghnutChartWhatIf) {

                        showChart = <DoghnutChartWhatIfAnalaysisLayout predictedDate={formatDate(date)}
                                                                       actualDate={formatDate(previousDate)}
                                                                       actualValues={marketViewArrayActualValues}
                                                                       actualLabels={marketViewArrayActualLabels}
                                                                       predictedValues={marketViewArrayPredictedValues}
                                                                       predictedLabels={marketViewArrayPredictedLabels}
                                                                       fmcgid = {fmcgid}
                                                                       size = {size}
                                                                       retailer = {retailer}
                                                                        category = {category}></DoghnutChartWhatIfAnalaysisLayout>

                    } else {


//alert(last3MonthAverageSalesList.length);
                        let charttitle = isValuePack(product)?`${product} Prediction (Singles)`:`${product} Prediction`;
                            showChart = <><LineChart charttitle={charttitle}
                                                     chartsubtitlePredict={`Price:Ksh${effectivePrice}     Discount:${discount}%   Period:${period}wk`}
                                                     chartsubtitleLocked={`Price:Ksh${lockedEffectivePrice}     Discount:${lockedDiscount}%   Period:${lockedPeriod}wk`}
                                                     placeholdercharttitle={`${placeHolderProductLabel} Prediction`}
                                                     showPlaceHolder={showPlaceHolderProductLabel}
                                                     xAxis={lineChartXAxis}
                                                     yAxis={lineChartYAxis}
                                                     yAxisPredicted={lineChartYAxisPredicted}
                                                     totalPredictedSales={totalPredictedSalesQty}
                                                     currentPrice={currentPrice} proposedPrice={proposedPrice}
                                                     effectivePrice={effectivePrice} previousPrice={previousPrice}
                                                     lockedEffectivePrice={lockedEffectivePrice}
                                                     previousMonthTotalQty={totalpreviousMonthQty}
                                                     previousMonthSelectedProductQty={previousMonthProductQty}
                                                     previousMonthResults = {peviousMonthResults}
                                                     onHandleClearButtonClicked={handleClearEventButtonClicked}
                                                     disableLockPrediction={disableLockPrediction}
                                                     onHandleLockPrediction={handleLockPredictionEvent}
                                                     product={product}
                                                     category={category}
                                                     subcategory={subcategory}
                                                     brand={brand}
                                                     size={size}
                                                     segment = {segment}
                                                     retailer={retailerName}
                                                     retailerId = {retailer}
                                                     date={date}
                                                     discount={discount}
                                                     absolutediscount = {absoluteDiscount}
                                                     price={proposedPrice}
                                                     period={period}
                                                     last3MonthSalesList = {last3MonthAverageSalesList}
                                                     imageurl={imageUrl}
                                                     last3monthValue={last3MonthAverageValue}
                                                     linecharttype={linechartType}
                                                     predictedResults = {predictedResults}
                                                     previousValuesBrands = {previousValuesBrands}
                                                     fmcgId = {fmcgid}
                                                     showStackBar = {showStackBarWhatIf}
                                                     priceList = {currentPricesList}


                            />

                                <AlertDialog dialogStatus={alertDialogStatus} dialogTitle={dialogTitle}
                                             dialogText={dialogText} onCloseAlertDialog={handleCloseAlertDialog}/>

                            </>

                    }
                } else {
                    showChart = <>
                        <p></p>
                        <AlertDialog dialogStatus = {alertDialogStatus} dialogTitle = {dialogTitle} dialogText = {dialogText} onCloseAlertDialog = {handleCloseAlertDialog}/>

                    </>;
                    showButtonGroup = <p></p>;
                }
            } else {
                showChart = <CircularProgress/>

            }
        }
    } else {
        //showChart = <ChartsLayout/>
        showChart = <p></p>
    }
    let layoutView;
    if(showLandingPage){
        //not logged in
        if (token === null || token === "") {
            layoutView = <LandingPage onHandleLoginButton={handleLoginButtonLandingPage}/>
        }else {
            //logged in

            if(showMyPlan || showSettings){
                layoutView = <>  {header}
                    {sidebar}

                    <ComingSoonImage isShowMyPlan = {showMyPlan} isShowmySettings = {showSettings}/>

                </>

            }else {
                layoutView = <>
                    {header}
                    {sidebar}
                    <div className="maincontent">


                        <Target sectionname="Target"
                                img_url={imageUrl}
                                fmcgId = {fmcgid}
                                brand = {brand}
                                size = {size}
                                segment = {segment}
                                category={selectedSubCategory}
                                modelId = {selecteModelId}
                                onhandleDatePickerChange={handleDatePickerSelect}
                                onhandleDropDownStateChange={handleDropDownStateChange}
                                retailer = {retailer}
                                showAutomateState = {showAutomate}/>


                        {leverComponent}

                        {showButtonGroup}
                        {showChart}


                    </div>
                </>
            }
        }
    }else {
        if (token === null || token === "") {
            if (showAuthentication) {
                if (showLoginScreen) {


                    layoutView =
                        <>
                            {header}
                            <div className="maincontent">
                                <LoginForm onLoginUser={loginUser}
                                           onDontHaveAccountStateChange={handleDontHaveAccount}
                                           onBackPress = {handleLoginBackPress}
                                           showLoader={showLoginLoader}
                                           showAlert={showLoginAlert}
                                           alertMessage={loginAlertMessage}/>
                                <AlertDialog dialogStatus = {alertDialogStatus} dialogTitle = {dialogTitle} dialogText = {dialogText} onCloseAlertDialog = {handleCloseAlertDialog}/>

                            </div>

                        </>

                } else {
                    if (showRegistrationScreen) {
                        layoutView =
                            <>
                                {header}
                                <div className="maincontent"><RegistrationForm onRegisterUser={registerUser}
                                                                               onGoBackStateChange={handleGoBackButtonInAccountRegistration}
                                                                               showLoader={showRegistrationLoader}
                                                                               showAlert={showRegistrationAlert}
                                                                               alertMessage={registrationAlertMessage}
                                                                               alertType={registrationAlertType}/>
                                </div>
                            </>
                    }
                }
            } else {
                layoutView = <>
                    {header}
                    {sidebar}
                    <div className="maincontent">


                        <Target sectionname="Target"
                                img_url={imageUrl}
                                fmcgId = {fmcgid}
                                brand = {brand}
                                size = {size}
                                segment = {segment}
                                retailer = {retailer}
                                modelId = {selecteModelId}
                                onhandleDatePickerChange={handleDatePickerSelect}
                                onhandleDropDownStateChange={handleDropDownStateChange}
                                category={selectedSubCategory}
                                showAutomateState = {showAutomate}/>


                        {leverComponent}

                        {showButtonGroup}
                        {showChart}


                    </div>
                </>

            }

        } else {
            if (showMyPlan || showSettings) {
                layoutView = <>  {header}
                    {sidebar}

                    <ComingSoonImage isShowMyPlan = {showMyPlan} isShowmySettings = {showSettings}/>

                </>
            } else {
                layoutView = <>
                    {header}
                    {sidebar}
                    <div className="maincontent">


                        <Target sectionname="Target"
                                img_url={imageUrl}
                                category={selectedSubCategory}
                                fmcgId = {fmcgid}
                                brand = {brand}
                                size = {size}
                                segment = {segment}
                                modelId = {selecteModelId}
                                retailer = {retailer}
                                onhandleDatePickerChange={handleDatePickerSelect}
                                onhandleDropDownStateChange={handleDropDownStateChange}
                                showAutomateState = {showAutomate}
                                />


                        {leverComponent}

                        {showButtonGroup}
                        {showChart}


                    </div>
                </>
            }
        }

    }


//


    return (
        <>


            {layoutView}

        </>

    );
}

export default App;
