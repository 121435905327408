
  import styles from './stylesheets/fxcomparison.module.css'
  function FxComparisonHeader(){

  return (<>
      <div className={styles.headerlayout}>
          <div>

          <p className={styles.headertexttitle}><span>Compare</span> FX rates; Get the <span>best price</span></p>
              <p className={styles.headertextsubtitle}>  Don’t <span>leave money</span> on the table!</p>
          </div>
      </div>
      </>


  )

  }

  export default FxComparisonHeader